import React, {
    Component
} from "react";

import {
    Card,
    CardBody,
    CardTitle,
    Button,
    Modal,
} from "reactstrap";
import Select from "react-select";
import {
    NavLink,
    withRouter
} from "react-router-dom";
import {
    getData
} from "../../helpers/requests.js";
import { apiLink } from '../../helpers/commons.js';
//import SweetAlert from "react-bootstrap-sweetalert";
import axios from 'axios';
import wardrobe_size_image_1 from '../../assets/images/wardrobes/sizes_1.png';
import wardrobe_size_image_2 from '../../assets/images/wardrobes/sizes_2.png';
import wardrobe_size_image_3 from '../../assets/images/wardrobes/sizes_3.png';
import wardrobe_size_image_4 from '../../assets/images/wardrobes/sizes_4.png';
import wardrobe_size_image_5 from '../../assets/images/wardrobes/sizes_5.png';
import wardrobe_size_image_6 from '../../assets/images/wardrobes/sizes_6.png';
import wardrobe_size_image_7 from '../../assets/images/wardrobes/sizes_7.png';
import wardrobe_size_image_8 from '../../assets/images/wardrobes/sizes_8.png';

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

class WardrobeVariant {
    material = null;
    regions = [];
    materialsErrors = [];
};

const colors = ['Kolor A', 'Kolor B', 'Kolor C', 'Kolor D']
const wardrobesImages = [
    wardrobe_size_image_1,
    wardrobe_size_image_2,
    wardrobe_size_image_3,
    wardrobe_size_image_4,
    wardrobe_size_image_5,
    wardrobe_size_image_6,
    wardrobe_size_image_7,
    wardrobe_size_image_8,
];

class WardobesConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isSaving: false,
            toggleAddMaterial: false,
            textures: [],
            materials: [{options: []}],
            activeColor: 0,
            wardrobes: [
                {
                    name: 'Nowoczesne',
                    types: [
                        {
                            name: 'Szafa przesuwna',
                            kind_name: 'nowoczesna',
                            sizes_image: 0,
                            content: {
                                profile_variants: [],
                                front_variants: [
                                    {
                                        name: 'A',
                                        variants: []
                                    },
                                    {
                                        name: 'B',
                                        variants: []
                                    }
                                ],
                                base_variants: [],
                                inside_variants: [],
                                orto_variants: [],
                                back_variants: [],
                                inside_handle_variants: []
                            }
                        },
                        {
                            name: 'Szafa uchylna',
                            kind_name: 'nowoczesna',
                            sizes_image: 1,
                            content: {
                                profile_variants: [],
                                handle_variants: [],
                                front_variants: [
                                    {
                                        name: 'A',
                                        variants: []
                                    }
                                ],
                                base_variants: [],
                                inside_variants: [],
                                orto_variants: [],
                                back_variants: [],
                                inside_handle_variants: []
                            }
                        }
                    ]
                },
                {
                    name: 'Minimalistyczne',
                    types: [
                        {
                            name: 'Szafa przesuwna',
                            kind_name: 'minimalistyczna',
                            sizes_image: 2,
                            content: {
                                profile_variants: [],
                                front_variants: [
                                    {
                                        name: 'A',
                                        variants: []
                                    }
                                ],
                                base_variants: [],
                                inside_variants: [],
                                orto_variants: [],
                                back_variants: [],
                                inside_handle_variants: []
                            }
                        },
                        {
                            name: 'Szafa uchylna',
                            kind_name: 'minimalistyczna',
                            sizes_image: 3,
                            content: {
                                profile_variants: [],
                                handle_variants: [],
                                front_variants: [
                                    {
                                        name: 'A',
                                        variants: []
                                    }
                                ],
                                base_variants: [],
                                inside_variants: [],
                                orto_variants: [],
                                back_variants: [],
                                inside_handle_variants: []
                            }
                        }
                    ]
                },
                {
                    name: 'Awangardowe',
                    types: [
                        {
                            name: 'Szafa przesuwna',
                            kind_name: 'awangardowa',
                            sizes_image: 4,
                            content: {
                                profile_variants: [],
                                front_variants: [
                                    {
                                        name: 'A',
                                        variants: []
                                    },
                                    {
                                        name: 'B',
                                        variants: []
                                    },
                                    {
                                        name: 'C',
                                        variants: []
                                    },
                                    {
                                        name: 'D',
                                        variants: []
                                    }
                                ],
                                base_variants: [],
                                inside_variants: [],
                                orto_variants: [],
                                back_variants: [],
                                inside_handle_variants: []
                            }
                        },
                        {
                            name: 'Szafa uchylna',
                            kind_name: 'awangardowa',
                            sizes_image: 5,
                            content: {
                                profile_variants: [],
                                handle_variants: [],
                                front_variants: [
                                    {
                                        name: 'A',
                                        variants: []
                                    },
                                    {
                                        name: 'B',
                                        variants: []
                                    },
                                    {
                                        name: 'C',
                                        variants: []
                                    }
                                ],
                                base_variants: [],
                                inside_variants: [],
                                orto_variants: [],
                                back_variants: [],
                                inside_handle_variants: []
                            }
                        }
                    ]
                },
                {
                    name: 'Klasyczne',
                    types: [
                        {
                            name: 'Szafa przesuwna',
                            kind_name: 'klasyczna',
                            sizes_image: 6,
                            content: {
                                profile_variants: [],
                                front_variants: [
                                    {
                                        name: 'A',
                                        variants: []
                                    },
                                    {
                                        name: 'B',
                                        variants: []
                                    }
                                ],
                                base_variants: [],
                                inside_variants: [],
                                orto_variants: [],
                                back_variants: [],
                                inside_handle_variants: []
                            }
                        },
                        {
                            name: 'Szafa uchylna',
                            kind_name: 'klasyczna',
                            sizes_image: 7,
                            content: {
                                profile_variants: [],
                                handle_variants: [],
                                front_variants: [
                                    {
                                        name: 'A',
                                        variants: []
                                    },
                                    {
                                        name: 'B',
                                        variants: []
                                    }
                                ],
                                base_variants: [],
                                inside_variants: [],
                                orto_variants: [],
                                back_variants: [],
                                inside_handle_variants: []
                            }
                        }
                    ],
                },
                {
                    name: 'System Lumi',
                    types: [
                        {
                            name: 'Drzwi przesuwne',
                            kind_name: '',
                            sizes_image: null,
                            is_lumi: true,
                            content: {
                                profile_variants: [],
                                fill_variants: []
                            }
                        },
                        {
                            name: 'Drzwi uchylne',
                            kind_name: '',
                            sizes_image: null,
                            is_lumi: true,
                            content: {
                                profile_variants: [],
                                fill_variants: []
                            }
                        },
                        {
                            name: 'Drzwi z ościeżnicą',
                            kind_name: '',
                            sizes_image: null,
                            is_lumi: true,
                            content: {
                                profile_variants: [],
                                fill_variants: []
                            }
                        },
                        {
                            name: 'Witryny',
                            kind_name: '',
                            sizes_image: null,
                            is_lumi: true,
                            content: {
                                profile_variants: [],
                                fill_variants: []
                            }
                        }
                    ]
                },
                {
                    name: 'Garderoby',
                    types: [
                        {
                            name: 'Garderoba',
                            kind_name: '',
                            sizes_image: null,
                            is_wardrobe: true,
                            content: {
                                base_variants: [],
                                back_variants: [],
                                inside_variants: [],
                                orto_variants: [],
                                inside_handle_variants: []
                            }
                        }
                    ]
                }
            ],
            selectedConfig: null
        }
        this.renderMenu = this.renderMenu.bind(this);
        this.openEdit = this.openEdit.bind(this);
        this.closeEdit = this.closeEdit.bind(this);
        this.handleSelectedRegions = this.handleSelectedRegions.bind(this);
        this.handleAddVariant = this.handleAddVariant.bind(this);
        this.handleRemoveVariant = this.handleRemoveVariant.bind(this);
        this.renderVariantSection = this.renderVariantSection.bind(this);
        this.rednerVariant = this.rednerVariant.bind(this);
        this.renderUnusedRegions = this.renderUnusedRegions.bind(this);
        this.handleSelectedCategory = this.handleSelectedCategory.bind(this);
        this.handleActiveColor = this.handleActiveColor.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
        this.validateMaterialVariantArray = this.validateMaterialVariantArray.bind(this);
        this.updateMaterial = this.updateMaterial.bind(this);

    }

    async initializeView() {
        this.setState({ isLoading: true });
        let sessionData = JSON.parse(sessionStorage.getItem('authUser'));
        let data = {
            token: sessionData.user_token
        }

        axios.post(apiLink('admin/getwardrobessetup'), data)
            .then(res => {
                if (res.data.success === true) {
                        let wardrobes = JSON.parse(res.data.data.config.content)

                        for (var i = 0; i <= 3; i++) {
                            if (wardrobes[i].types[0].content.inside_handle_variants === undefined) {
                                wardrobes[i].types[0].content.inside_handle_variants = [];
                            }
                            if (wardrobes[i].types[1].content.inside_handle_variants === undefined) {
                                wardrobes[i].types[1].content.inside_handle_variants = [];
                            }
                        }

                        if (wardrobes[5] === undefined) {
                            wardrobes.push(
                                {
                                    name: 'Garderoby',
                                    types: [
                                        {
                                            name: 'Garderoba',
                                            kind_name: '',
                                            sizes_image: null,
                                            is_wardrobe: true,
                                            content: {
                                                base_variants: [],
                                                back_variants: [],
                                                inside_variants: [],
                                                orto_variants: [],
                                                inside_handle_variants: []
                                            }
                                        }
                                    ]
                                }
                            );
                        }
            
                        console.log(wardrobes)
                        this.setState({wardrobes:  wardrobes});
                    };
                })
            .catch(err => console.log(err.response))


        try {
            var response = await getData(apiLink('admin/getData'), 'materials');
        } catch (error) {
            console.log(error);
            this.props.history.push('/logout');
            return;
        }

        response = response.data;
        let materials = response.materials;
        let textures = response.textures;

        let regions = response.regions;

        this.setState({
            toggleAddMaterial: false,
            regions: regions,
            materials: [{ options: materials }],
            textures: textures,
            isLoading: false,
        });
        
        this.checkMaterials();
    }

    checkMaterials() {
        let config = this.state.wardrobes;

        for (var i = 0; i < 4; i++) {
            config[i].types.forEach(wardrobeType => {
                wardrobeType.content.back_variants.forEach(variant => { this.validateMaterialVariant(variant) });
                if (wardrobeType.content.handle_variants !== undefined) {
                    wardrobeType.content.handle_variants.forEach(variant => { this.validateMaterialVariant(variant) });
                }
                wardrobeType.content.base_variants.forEach(variant => { this.validateMaterialVariant(variant) });
                wardrobeType.content.inside_variants.forEach(variant => { this.validateMaterialVariant(variant) });
                wardrobeType.content.orto_variants.forEach(variant => { this.validateMaterialVariant(variant) });
                wardrobeType.content.profile_variants.forEach(variant => { this.validateMaterialVariant(variant) });
                wardrobeType.content.front_variants.forEach(frontVariant => {
                    frontVariant.variants.forEach(variant => { this.validateMaterialVariant(variant) });
                });
            });
        }
        this.setState({wardrobes: config});
    }

    validateMaterialVariantArray(variantArray) {
        var validation = true;

        if (this.state.isLoading) return true;
        if (this.state.toggleAddMaterial) return true;
        if (variantArray === undefined) return true;
        if (variantArray.length === 0) return false;

        variantArray.forEach(variant => {
            if (variant.material === null) {
                validation = false;
            } else {
                let match = this.state.materials[0].options.find( m => m.id === variant.material.id);
                if (variant.material.is_available !== "1") {
                    validation = false;
                }
                if (match === undefined) {
                    validation = false;
                } else {
                    if (!this.compareRegions(match.region, variant.material.region)) {
                        console.log('nope');
                        validation = false;
                    }
                }
            }
        });
        return validation;
    }

    validateMaterialVariant(variant) {
        if (variant.material === null) {
            return;
        }
        variant.materialsErrors = [];
        let match = this.state.materials[0].options.find( m => m.id === variant.material.id);
        if (variant.material.is_available !== "1") {
            console.log(variant.material.id);
            console.log("material disabled");
            variant.materialsErrors.push("Materiał jest niedostępny")
        }
        if (match === undefined) {
            console.log(variant.material.id);
            console.log("material removed");
            variant.materialsErrors.push("Materiał nie istnieje")
        } else {
            if (!this.compareRegions(match.region, variant.material.region)) {
                console.log(variant.material.id);
                console.log("material regions error ");
                variant.materialsErrors.push("Materiał zmienił regiony")
            }
        }
    }

    compareRegions(regionA, regionB) {
        if (regionA.length === regionB.length) {
            for (var i = 0; i< regionA.length; i++) {
                if (regionA.region_id !== regionB.region_id) {
                    return false;
                }
            }

            return true;
        }

        return false;
    }

    updateMaterial(material, variant) {
        let match = this.state.materials[0].options.find( m => m.id === material.id);
        this.handleSelectedCategory(match, variant);
        this.validateMaterialVariant(variant);
    }

    saveConfig() {
        this.setState({ isSaving: true });

        let sessionData = JSON.parse(sessionStorage.getItem('authUser'));
        let data = {
            token: sessionData.user_token,
            config: this.state.wardrobes
        }
        axios.post(apiLink('admin/savewardrobessetup'), data)
            .then(res => {
                this.setState({ isSaving: false });
                if (res.data.success === true) {
                        toastr.success("Konfiguracja została zapisana", "");
                        this.initializeView();
                    };
                })
            .catch(err => {
                this.setState({ isSaving: false });
                toastr.error("Błąd połączenia z serwerem", "");
            })
    }

    openEdit(config) {
        this.setState({
            selectedConfig: config,
            toggleAddMaterial: true,
            activeColor: 0
        });
    }

    closeEdit() {
        this.setState({
            toggleAddMaterial: false,
        });

        this.initializeView();
    }

    handleSelectedRegions(regionId, variant, isDisabled = true) {
        if (isDisabled) {
            return;
        }
        let index = variant.regions.indexOf(regionId);
        if (index === -1) {
            variant.regions.push(regionId);
        } else {
            variant.regions.splice(index, 1);
        }
        this.setState(this.state.selectedConfig);
    }

    handleSelectedCategory(material, variant) {
        variant.material = material;

        variant.regions = [];
        material.region.forEach(region => {
            variant.regions.push(region.region_id);
        });
        this.setState(this.state.selectedConfig);
    }

    handleAddVariant(variantArray) {
        // let disabledRegions = [];
        // variantArray.forEach(v => {
        //     v.regions.forEach(r => {
        //         disabledRegions.push(r);
        //     });
        // });

        let newVariant = new WardrobeVariant();

        // this.state.regions.forEach(region => {
        //     if (disabledRegions.indexOf(region.id) === -1) {
        //         newVariant.regions.push(region.id);
        //     }
        // });

        variantArray.push(newVariant);

        this.setState( this.state.selectedConfig );
    }

    handleRemoveVariant(variant, variantArray) {
        let index = variantArray.indexOf(variant);
        if (index > -1) {
            variantArray.splice(index, 1);
        }
        this.setState( this.state.selectedConfig );
    }

    handleActiveColor(id) {
        this.setState({
            activeColor: id
        });
    }

    renderVariantSection(variantSection) {
        return (
            <div className="">
                <button type="button" className="btn btn-success waves-effect waves-light float-right" onClick={() => {this.handleAddVariant(variantSection)} } >
                Dodaj wariant
                </button>
                { this.renderUnusedRegions(variantSection) }
                <div className="clearfix"></div>
                <div className="row">
                    <div className="col-sm-12 mt-4">
                        { variantSection.map((variant, index) =>
                            this.rednerVariant(variant, index, variantSection)
                        ) }
                    </div>
                </div>
            </div>
        );
    }

    rednerVariant(variant, index, variantArray) {
        let disabledRegions = [];
        variantArray.filter(v => v !== variant).forEach(v => {
            v.regions.forEach(r => {
                disabledRegions.push(r);
            });
        });

        return (
            <div key={index} className={variant.materialsErrors.length === 0 ? ("border bg-light rounded p-3 mb-4 shadow") : ("border bg-warning rounded p-3 mb-4 shadow")}>
                <p className="font-size-14 mb-0 align-middle mb-4" style={{lineHeight: "36px"}}>
        Wariant {index+1} { variant.materialsErrors.length > 0 ? ( <span className="alert alert-danger ml-4"><i className="bx bx-error font-size-24 text-danger align-text-bottom mr-2"></i>{ variant.materialsErrors.map(e =>  <span className="mr-2">{ e }</span>  ) }</span> ) : null }

                <button type="button" className="btn btn-danger waves-effect waves-light float-right" onClick={() => { this.handleRemoveVariant(variant, variantArray) }} >
                    Usuń
                </button>
                { variant.materialsErrors.find(s => s === "Materiał zmienił regiony") !== undefined ? (
                <button type="button" className="btn btn-success waves-effect waves-light float-right mr-2" onClick={() => { this.updateMaterial(variant.material, variant) }} >
                    Zaktualizuj
                </button>
                ) : null }
                </p>

                <Select
                    placeholder="Wybierz"
                    isMulti={false}
                    value={variant.material}
                    onChange={(e) => this.handleSelectedCategory(e, variant)}
                    options={this.state.materials}
                    getOptionValue={option => option['id']}
                    getOptionLabel={option => option['name'] + ' - ' + option['name_app']}
                    classNamePrefix="select2-selection"
                    isDisabled={variant.materialsErrors.length > 0}
                />
                <div className="row">
                    <div className="col-sm-12 mt-4">
                    {
                        this.state.regions.map((region) =>
                        
                        <div key={ region.id } className="custom-control custom-checkbox mb-3 col-lg-4 float-left" >
                            <input type="checkbox" className="custom-control-input" id={"regionID_" + (region.id)} disabled={true} checked={ variant.regions.indexOf(region.id) > -1 } />
                            <label className="custom-control-label" onClick={null} >{ region.name }</label>
                        </div>
                    )
                    }
                    </div>
                </div>
            </div>
        );
    }

    renderUnusedRegions(variants) {
        let usedVariants = [];
        variants.forEach(variant => {
            variant.regions.forEach(region => {
                if (usedVariants.indexOf(region) === -1) {
                    usedVariants.push(region);
                }
            });
        });
        if (usedVariants.length === this.state.regions.length) {
            return null;
        }
        return (
            <div className="mt-4 alert alert-danger fade show float-left col-sm-12">
                <p>Nieobsłużone regiony:</p>
                {
                    this.state.regions.filter((r) => usedVariants.indexOf(r.id) === -1 ).map((region) =>
                        <span className="badge badge badge-danger font-size-12 m-1" key={ region.id }>{ region.name }</span>
                    )
                }
            </div>
        )
    }

    renderMenu(id) {
        let config = this.state.wardrobes[id];
        let frontVariantsErrors1 = 0;
        let frontVariantsErrors2 = 0;
        let type1_front_variants = 0;
        config.types[0].content.front_variants.forEach(element => {
            type1_front_variants += element.variants.length;
            element.variants.forEach(e => {
                if (e.materialsErrors !== undefined) {
                    if (e.materialsErrors.length > 0) {
                        frontVariantsErrors1++;
                    }
                }
            });
        });
        let type2_front_variants = 0;
        config.types[1].content.front_variants.forEach(element => {
            type2_front_variants += element.variants.length;
            element.variants.forEach(e => {
                if (e.materialsErrors !== undefined) {
                    if (e.materialsErrors.length > 0) {
                        frontVariantsErrors2++;
                    }
                }
            });
        });

        return (
            <div className="col-sm-3">
            <Card>
                <CardBody className="border-bottom">
                    <CardTitle className="m-0">
                        { config.name }
                    </CardTitle>
                </CardBody>
                <CardBody>
                    <div className="border rounded p-3 mb-4 shadow">
                        <p className="font-size-14 mb-0 align-middle font-weight-bold" style={{lineHeight: "26px"}}>
                        { config.types[0].name }
                        
                        <Button type="button" color="primary" size="sm" className="waves-effect waves-light float-right" onClick={ () =>  {this.openEdit(config.types[0])} }>
                            <i className="bx bx-edit font-size-14 align-middle"></i>
                        </Button>
                        </p>
                        <div className="mt-4">
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów profili: { config.types[0].content.profile_variants.length } { !this.validateMaterialVariantArray(config.types[0].content.profile_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów frontów: { type1_front_variants }  { frontVariantsErrors1 > 0 ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów kopusu: { config.types[0].content.base_variants.length } { !this.validateMaterialVariantArray(config.types[0].content.base_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów wnętrza: { config.types[0].content.inside_variants.length } { !this.validateMaterialVariantArray(config.types[0].content.inside_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów ORTO: { config.types[0].content.orto_variants.length } { !this.validateMaterialVariantArray(config.types[0].content.orto_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów pleców: { config.types[0].content.back_variants.length } { !this.validateMaterialVariantArray(config.types[0].content.back_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów uchwytów wnętrza: { config.types[0].content.inside_handle_variants.length } { !this.validateMaterialVariantArray(config.types[0].content.inside_handle_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                        </div>
                    </div>
                    <div className="border rounded p-3 shadow">
                        <p className="font-size-14 mb-0 align-middle font-weight-bold" style={{lineHeight: "26px"}}>
                        { config.types[1].name }
                        <Button type="button" color="primary" size="sm" className="waves-effect waves-light float-right" onClick={ () =>  {this.openEdit(config.types[1])} }>
                            <i className="bx bx-edit font-size-14 align-middle"></i>
                        </Button>
                        </p>
                        <div className="mt-4">
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów profili: { config.types[1].content.profile_variants.length } { !this.validateMaterialVariantArray(config.types[1].content.profile_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów uchwytów: { config.types[1].content.handle_variants.length } { !this.validateMaterialVariantArray(config.types[1].content.handle_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów frontów: { type2_front_variants }  { frontVariantsErrors2 > 0 ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów kopusu: { config.types[1].content.base_variants.length } { !this.validateMaterialVariantArray(config.types[1].content.base_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów wnętrza: { config.types[1].content.inside_variants.length } { !this.validateMaterialVariantArray(config.types[1].content.inside_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów ORTO: { config.types[1].content.orto_variants.length } { !this.validateMaterialVariantArray(config.types[1].content.orto_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów pleców: { config.types[1].content.back_variants.length } { !this.validateMaterialVariantArray(config.types[1].content.back_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów uchwytów wnętrza: { config.types[1].content.inside_handle_variants.length } { !this.validateMaterialVariantArray(config.types[1].content.inside_handle_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
        );
    }

    renderMenuLumi(id) {
        let config = this.state.wardrobes[id];

        return (
            <div className="col-sm-3">
            <Card>
                <CardBody className="border-bottom">
                    <CardTitle className="m-0">
                        { config.name }
                    </CardTitle>
                </CardBody>
                <CardBody>
                    <div>
                { config.types.map((type, index) =>
                    <div key={index} className="border rounded p-3 mb-4 shadow">
                        <p className="font-size-14 mb-0 align-middle font-weight-bold" style={{lineHeight: "26px"}}>
                        { type.name }
                        
                        <Button type="button" color="primary" size="sm" className="waves-effect waves-light float-right" onClick={ () =>  {this.openEdit(type)} }>
                            <i className="bx bx-edit font-size-14 align-middle"></i>
                        </Button>
                        </p>
                        <div className="mt-4">
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów profili: { type.content.profile_variants.length } { !this.validateMaterialVariantArray(type.content.profile_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów wypełnienia: { type.content.fill_variants.length } { !this.validateMaterialVariantArray(type.content.fill_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                        </div>
                    </div>
                )}
                </div>
                </CardBody>
            </Card>
        </div>
        );
    }

    renderMenuWarderobe(id) {
        let config = this.state.wardrobes[id];

        return (
            <div className="col-sm-3">
            <Card>
                <CardBody className="border-bottom">
                    <CardTitle className="m-0">
                        { config.name }
                    </CardTitle>
                </CardBody>
                <CardBody>
                    <div>
                { config.types.map((type, index) =>
                    <div key={index} className="border rounded p-3 mb-4 shadow">
                        <p className="font-size-14 mb-0 align-middle font-weight-bold" style={{lineHeight: "26px"}}>
                        { type.name }
                        
                        <Button type="button" color="primary" size="sm" className="waves-effect waves-light float-right" onClick={ () =>  {this.openEdit(type)} }>
                            <i className="bx bx-edit font-size-14 align-middle"></i>
                        </Button>
                        </p>
                        <div className="mt-4">
                            {/* <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów profili: { type.content.profile_variants.length } { !this.validateMaterialVariantArray(type.content.profile_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p> */}
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów kopusu: { type.content.base_variants.length } { !this.validateMaterialVariantArray(type.content.base_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów pleców: { type.content.back_variants.length } { !this.validateMaterialVariantArray(type.content.back_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów wnętrza: { type.content.inside_variants.length } { !this.validateMaterialVariantArray(type.content.inside_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów ORTO: { type.content.orto_variants.length } { !this.validateMaterialVariantArray(type.content.orto_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>
                            <p style={{lineHeight: "36px"}} className="mt-2 mb-0">Ilość wariantów uchwytów wnętrza: { type.content.inside_handle_variants.length } { !this.validateMaterialVariantArray(type.content.inside_handle_variants) ? ( <i className="bx bx-error font-size-24 text-danger align-text-bottom"></i> ) : null }</p>

                        </div>
                    </div>
                )}
                </div>
                </CardBody>
            </Card>
        </div>
        );
    }
    
    resetStates() {
 
    }
    
    componentDidMount() {
        this.initializeView();
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-title-box">
                    <h4 className="mb-0">
                    Szafy wzorcowe
                    </h4>
                </div>
                <div className="row">
                    { this.renderMenu(0) }
                    { this.renderMenu(1) }
                    { this.renderMenu(2) }
                    { this.renderMenu(3) }

                    { this.renderMenuLumi(4) }
                    { this.renderMenuWarderobe(5) }
                </div>

                { this.state.selectedConfig !== null ? (
                <Modal size="xl" isOpen={this.state.toggleAddMaterial} scrollable={true} >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Edycja - { this.state.selectedConfig.name } { this.state.selectedConfig.kind_name }
                        </h5>
                        <button
                        type="button"
                        onClick={() =>
                            this.closeEdit()
                        }
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    { this.state.selectedConfig.is_lumi === true ? (
                        <div className="modal-body">
                        <h1 className="mt-4">Ogólne</h1>
                        <hr></hr>
                        <h4 className="mt-4" style={{marginBottom: "-30px"}}>Profil</h4>
                        { this.renderVariantSection(this.state.selectedConfig.content.profile_variants) }

                        <h1 className="mt-4">Panele</h1>
                        <hr></hr>
                        <h4 className="mt-4" style={{marginBottom: "-30px"}}>Wypełnienie</h4>
                        { this.renderVariantSection(this.state.selectedConfig.content.fill_variants) }
                    </div>
                    ) : this.state.selectedConfig.is_wardrobe === true ? (
                        <div className="modal-body">
                        <h1 className="mt-4">Ogólne</h1>
                        <hr></hr>

                        <h1 className="mt-4">Korpus i wnętrze</h1>
                        <hr></hr>
                        <h4 className="mt-4" style={{marginBottom: "-30px"}}>Korpus</h4>
                        { this.renderVariantSection(this.state.selectedConfig.content.base_variants) }

                        <hr></hr>
                        <h4 className="mt-4" style={{marginBottom: "-30px"}}>Plecy</h4>
                        { this.renderVariantSection(this.state.selectedConfig.content.back_variants) }

                        <hr></hr>
                        <h4 className="mt-4" style={{marginBottom: "-30px"}}>Wnętrze</h4>
                        { this.renderVariantSection(this.state.selectedConfig.content.inside_variants) }

                        <hr></hr>
                        <h4 className="mt-4" style={{marginBottom: "-30px"}}>Profile ORTO</h4>
                        { this.renderVariantSection(this.state.selectedConfig.content.orto_variants) }

                        <hr></hr>
                        <h4 className="mt-4" style={{marginBottom: "-30px"}}>Uchwyty wnętrza</h4>
                        { this.renderVariantSection(this.state.selectedConfig.content.inside_handle_variants) }
                        </div>
                    ) :
                    <div className="modal-body">
                        <h1 className="mt-4">Ogólne</h1>
                        <hr></hr>
                        <h4 className="mt-4" style={{marginBottom: "-30px"}}>Profil</h4>
                        { this.renderVariantSection(this.state.selectedConfig.content.profile_variants) }
                        
                        { this.state.selectedConfig.content.handle_variants !== undefined ? (
                        <div>
                            <hr></hr>
                            <h4 className="mt-4" style={{marginBottom: "-30px"}}>Uchwyt</h4>
                            { this.renderVariantSection(this.state.selectedConfig.content.handle_variants) }
                        </div>
                        ) : null }

                        <h1 className="mt-4">Fronty</h1>
                        <hr></hr>
                        <h4>Rozmiar</h4>
                        <div className="row mt-4 p-4">
                            <div className="col-sm-4">
                                <h4 className="text-secondary">Mała</h4>
                            </div>
                            <div className="col-sm-4">
                                <h4 className="text-secondary">Średnia</h4>
                            </div>
                            <div className="col-sm-4">
                                <h4 className="text-secondary">Duża</h4>
                            </div>
                        </div>
                        <img className="col-sm-12 mb-4" src={wardrobesImages[this.state.selectedConfig.sizes_image]} alt="rozmiary" />
                        <div className="btn-group btn-group-lg mb-4" style={{ display: "flex" }}>
                        { this.state.selectedConfig.content.front_variants.map((variant, index) => 
                            <button key={index} type="button" className={ this.state.activeColor === index ? 'btn btn-primary waves-effect waves-light' : 'btn btn-light waves-effect waves-light' } onClick={ () => this.handleActiveColor(index) } >
                                { colors[index] }
                            </button>
                        ) }
                        </div>

                        { this.state.selectedConfig.content.front_variants.map((variant, index) => 
                            <div key={index} className={ this.state.activeColor === index ? 'd-block' : 'd-none' }>
                                { this.renderVariantSection(variant.variants) }
                            </div>
                        ) }

                        <h1 className="mt-4">Korpus i wnętrze</h1>
                        <hr></hr>
                        <h4 className="mt-4" style={{marginBottom: "-30px"}}>Korpus</h4>
                        { this.renderVariantSection(this.state.selectedConfig.content.base_variants) }

                        <hr></hr>
                        <h4 className="mt-4" style={{marginBottom: "-30px"}}>Plecy</h4>
                        { this.renderVariantSection(this.state.selectedConfig.content.back_variants) }

                        <hr></hr>
                        <h4 className="mt-4" style={{marginBottom: "-30px"}}>Wnętrze</h4>
                        { this.renderVariantSection(this.state.selectedConfig.content.inside_variants) }

                        <hr></hr>
                        <h4 className="mt-4" style={{marginBottom: "-30px"}}>Profile ORTO</h4>
                        { this.renderVariantSection(this.state.selectedConfig.content.orto_variants) }


                        <hr></hr>
                        <h4 className="mt-4" style={{marginBottom: "-30px"}}>Uchwyty wnętrza</h4>
                        { this.renderVariantSection(this.state.selectedConfig.content.inside_handle_variants) }
                    </div>
                    }

                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => this.saveConfig() } >
                            Zapisz zmiany
                        </button>
                    </div>
                </Modal>
                ) : null }
                <Modal size="sm" isOpen={this.state.isSaving} scrollable={true} centered >
                    <div className="modal-body mt-3 text-center">
                                <p><span className="spinner-border spinner-border-sm mr-4 " role="status" aria-hidden="true"></span>Zapisywanie...</p>
                    </div>
                </Modal>

                <Modal size="sm" isOpen={this.state.isLoading} scrollable={true} centered >
                    <div className="modal-body mt-3 text-center">
                                <p><span className="spinner-border spinner-border-sm mr-4 " role="status" aria-hidden="true"></span>Wczytywanie...</p>
                    </div>
                </Modal>
            </React.Fragment>
        );   
    }
}

export default withRouter(WardobesConfig);
