import React, {
    Component
} from "react";

import {
    Card,
    CardBody,
    CardTitle,
    Button,
    Modal,
    Label,
    Input,
    FormGroup,
    Row,
    Col,
    Progress
} from "reactstrap";
import Select from "react-select";
import {
    withRouter
} from "react-router-dom";
import {
    getData,
    postData
} from "../../helpers/requests.js";
import { apiLink } from '../../helpers/commons.js';
import Dropzone from "react-dropzone";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from 'axios';

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const materialTypes = {
    STANDARD: 'materiał',
    PROFILE: 'profil', 
    ORTO: 'system orto',
    HANDLE: 'uchwyt'
}

const tralsnationLanguage = {
    en: 'Angielski'
}

class Materials extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isSaving: false,
            toggleAddMaterial: false,
            advancedMode: false,
            savingMaterial: false,
            advancedTextureUpload: { uploaded: false, progress: 0, color_id: null, parametric_id: null},
            thumbnail: { name: 'Miniatura JPG (opcjonalnie)', uploaded: false, progress: 0, files: [], id: null, astc: null},
            colorTexture1: { name: 'Mapa koloru JPG', uploaded: false, progress: 0, files: [], id: null, astc: null},
            normalTexture1: { name: 'Mapa parametrów JPG', uploaded: false, progress: 0, files: [], id: null, astc: null},
            
            colorTextureAstcSmall: { name: 'Mapa koloru ASTC', uploaded: false, progress: 0, files: [], id: null, astc: 'color_small'},
            paramTextureAstcSmall: { name: 'Mapa parametrów ASTC', uploaded: false, progress: 0, files: [], id: null, astc: 'param_small'},
            colorTextureAstcMedium: { name: 'Mapa koloru ASTC', uploaded: false, progress: 0, files: [], id: null, astc: 'color_medium'},
            paramTextureAstcMedium: { name: 'Mapa parametrów ASTC', uploaded: false, progress: 0, files: [], id: null, astc: 'param_medium'},
            colorTextureAstcLarge: { name: 'Mapa koloru ASTC', uploaded: false, progress: 0, files: [], id: null, astc: 'color_large'},
            paramTextureAstcLarge: { name: 'Mapa parametrów ASTC', uploaded: false, progress: 0, files: [], id: null, astc: 'param_large'},
            
            producers: [{ options: [] }],
            categories: [{ options: [] }],
            decors: [{ options: [] }],
            kinds: [{ options: [] }],
            kindsSub: [{ options: [] }],
            surfaces: [{ options: [] }],
            profiles: [{ options: [] }],
            handles: [{ options: [] }],
            materials: [],
            regions: [],
            colors: [],
            search: '',
            materialType: materialTypes.STANDARD,
            
            selectedAvailability: true,
            selectedName: '',
            selectedNameApplication: '',
            selectedName18: '',
            selectedName10: '',
            selectedName4: '',
            selectedVeneer: '',
            
            selectedProducer: null,
            selectedCategories: null,
            selectedRegions: null,
            selectedColors: null,
            selectedDecors: null,
            selectedKinds: null,
            selectedKindsSub: null,
            selectedProfiles: null,
            selectedHandles: null,
            selectedSurfaces: null,
            selectedHeight: 2800,
            selectedWidth: 1300,
            selectedMetalness: false,
            translations: null,
            
            materialEdit: null,
            sorting: { key: null, ascending: true },
            validationMessage: [],
            
            confirmDeletion: false,
            confirmDeletionMaterialID: null
        }
        this.toggleAddMaterial = this.toggleAddMaterial.bind(this);
        
        this.handleSelectedName = this.handleSelectedName.bind(this);
        this.handleSelectedNameApplication = this.handleSelectedNameApplication.bind(this);
        this.handleSelectedName18 = this.handleSelectedName18.bind(this);
        this.handleSelectedName10 = this.handleSelectedName10.bind(this);
        this.handleSelectedName4 = this.handleSelectedName4.bind(this);
        this.handleSelectedVeneer = this.handleSelectedVeneer.bind(this);
        this.handleTranslations = this.handleTranslations.bind(this);
        
        this.handleSelectedProducer = this.handleSelectedProducer.bind(this);
        this.handleSelectedCategory = this.handleSelectedCategory.bind(this);
        this.handleSelectedRegions = this.handleSelectedRegions.bind(this);
        this.handleSelectAllRegions = this.handleSelectAllRegions.bind(this);
        this.handleSelectedKinds = this.handleSelectedKinds.bind(this);
        this.handleSelectedKindsSub = this.handleSelectedKindsSub.bind(this);
        this.handleSelectedProfiles = this.handleSelectedProfiles.bind(this);
        this.handleSelectedHandles = this.handleSelectedHandles.bind(this);
        this.handleSelectedColors = this.handleSelectedColors.bind(this);
        this.handleSelectedDecors = this.handleSelectedDecors.bind(this);
        this.handleSelectedSurfaces = this.handleSelectedSurfaces.bind(this);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
        this.handleSaveMaterial = this.handleSaveMaterial.bind(this);
        this.handleSelectedMetalness = this.handleSelectedMetalness.bind(this);
        this.validateMaterial = this.validateMaterial.bind(this);
        this.handleEnterAdvancedMode = this.handleEnterAdvancedMode.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        
        this.handleTableSorting = this.handleTableSorting.bind(this);
        
        this.resetStates = this.resetStates.bind(this);
        this.initializeView = this.initializeView.bind(this);
    }

    resetStates() {
        let translations = {};
        for (const lang in tralsnationLanguage) {
                translations[lang] = { name: ''};
        }
        this.setState({
            isLoading: true,
            isSaving: false,
            toggleAddMaterial: false,
            advancedMode: false,
            savingMaterial: false,
            advancedTextureUpload: { uploaded: false, progress: 0, color_id: null, parametric_id: null},
            thumbnail: { name: 'Miniatura JPG (opcjonalnie)', uploaded: false, progress: 0, files: [], id: null, astc: 'thumbnail'},
            colorTexture1: { name: 'Mapa koloru JPG', uploaded: false, progress: 0, files: [], id: null, astc: null},
            normalTexture1: { name: 'Mapa parametrów JPG', uploaded: false, progress: 0, files: [], id: null, astc: null},
            
            colorTextureAstcSmall: { name: 'Mapa koloru ASTC', uploaded: false, progress: 0, files: [], id: null, astc: 'color_small'},
            paramTextureAstcSmall: { name: 'Mapa parametrów ASTC', uploaded: false, progress: 0, files: [], id: null, astc: 'param_small'},
            colorTextureAstcMedium: { name: 'Mapa koloru ASTC', uploaded: false, progress: 0, files: [], id: null, astc: 'color_medium'},
            paramTextureAstcMedium: { name: 'Mapa parametrów ASTC', uploaded: false, progress: 0, files: [], id: null, astc: 'param_medium'},
            colorTextureAstcLarge: { name: 'Mapa koloru ASTC', uploaded: false, progress: 0, files: [], id: null, astc: 'color_large'},
            paramTextureAstcLarge: { name: 'Mapa parametrów ASTC', uploaded: false, progress: 0, files: [], id: null, astc: 'param_large'},
            
            producers: [{ options: [] }],
            categories: [{ options: [] }],
            decors: [{ options: [] }],
            kinds: [{ options: [] }],
            kindsSub: [{ options: [] }],
            surfaces: [{ options: [] }],
            profiles: [{ options: [] }],
            handles: [{ options: [] }],
            materials: [],
            regions: [],
            colors: [],
            search: '',
            materialType: materialTypes.STANDARD,
            
            selectedAvailability: true,
            selectedName: '',
            selectedNameApplication: '',
            selectedName18: '',
            selectedName10: '',
            selectedName4: '',
            selectedVeneer: '',
            
            selectedProducer: null,
            selectedCategories: null,
            selectedRegions: null,
            selectedColors: null,
            selectedDecors: null,
            selectedKinds: null,
            selectedKindsSub: null,
            selectedProfiles: null,
            selectedHandles: null,
            selectedSurfaces: null,
            selectedHeight: 2800,
            selectedWidth: 1300,
            selectedMetalness: false,
            translations: translations,
            
            materialEdit: null,
            validationMessage: [],
            confirmDeletion: false,
            confirmDeletionMaterialID: null
        });
    }
    
    componentDidMount() {
        this.initializeView();
    }
    
    async initializeView() {
        this.setState({ isLoading: true });

        try {
            var response = await getData(apiLink('admin/getData'), 'materials');
        } catch (error) {
            console.log(error);
            this.props.history.push('/logout');
            return;
        }
        console.log(response);
        if (response.auth === false) {
            this.props.history.push('/logout');
            return;
        }

        response = response.data;
        
        
        let regions = response.regions;
        
        regions.forEach(region => {
            region.isChecked = false;
        });
        
        let colors = response.colors;
        
        colors.forEach(color => {
            color.isChecked = false;
        });
        
        let materials = response.materials;
        materials.forEach((m) => {
            if (m.producer.length > 0) {
                m.producerName = response.producers.find(p => p.id === m.producer[0].producer_id).name;
            } else {
                m.producerName = '-';
            }
            if (m.kind_id !== null) {
                m.kind = response.kinds.find(p => p.id === m.kind_id).name;
            } else {
                m.kind = '-';
            }
            if (m.surface_id !== null) {
                m.surface = response.surfaces.find(p => p.id === m.surface_id).name;
            } else {
                m.surface = '-';
            }
            m.filtered = true;
        });
        
        this.setState({
            materials: materials,
            regions: response.regions,
            selectedRegions: regions,
            producers: [{ options: response.producers }],
            categories: [{ options: response.categories }],
            colors: response.colors,
            selectedColors: colors,
            decors: [{ options: response.decors }],
            kinds: [{ options: response.kinds }],
            kindsSub: [{ options: response.kinds_sub }],
            surfaces: [{ options: response.surfaces }],
            profiles: [{ options: response.profiles }],
            handles: [{ options: response.handles }],
            isLoading: false
        });
        
        if (this.state.sorting.key !== null) {
            this.handleTableSorting(this.state.sorting.key, true);
        }
    }

    toggleAddMaterial(materialType = materialTypes.STANDARD) {
        this.resetStates();
        this.initializeView();
        
        this.setState({
            materialType,
            toggleAddMaterial: true
        });

        if (materialType === materialTypes.PROFILE) {
            this.setState({
                selectedCategories: [this.state.categories[0].options.find(c => c.name === 'profil')]
            });
        }

        if (materialType === materialTypes.ORTO) {
            this.setState({
                selectedCategories: [this.state.categories[0].options.find(c => c.name === 'system orto')]
            });
        }

        if (materialType === materialTypes.HANDLE) {
            this.setState({
                selectedCategories: [this.state.categories[0].options.find(c => c.name === 'uchwyt')]
            });
        }
    }
    
    handleSearch = event => {
        let needle = this.state.search.toLowerCase();
        let materials = this.state.materials;
        
        materials.forEach(mat => {
            if (mat.name.toLowerCase().search(needle) !== -1 || mat.name_app.toLowerCase().search(needle) !== -1) {
                mat.filtered = true;
            } else {
                mat.filtered = false;
            }
        });
        
        this.setState({ materials });
    }
    
    handleClearSearch = () => {
        let materials = this.state.materials;
        materials.forEach(mat => {
            mat.filtered = true;
        });
        
        this.setState({ materials });
        this.setState({ search: '' });
    }
    
    handleSelectedName = event => {
        this.setState({
            selectedName: event.target.value
        });
    }
    
    handleSelectedNameApplication = event => {
        this.setState({
            selectedNameApplication: event.target.value
        });
    }
    
    handleSelectedName18 = event => {
        this.setState({
            selectedName18: event.target.value
        });
    }
    
    handleSelectedName10 = event => {
        this.setState({
            selectedName10: event.target.value
        });
    }
    
    handleSelectedName4 = event => {
        this.setState({
            selectedName4: event.target.value
        });
    }
    
    handleSelectedVeneer = event => {
        this.setState({
            selectedVeneer: event.target.value
        });
    }

    handleSelectedProducer = selectedProducer => {
        this.setState({
            selectedProducer
        });
    }

    handleSelectedCategory = selectedCategories => {
        this.setState({
            selectedCategories
        });
    }
    
    handleSelectAllRegions(state) {
        let regions = this.state.regions;
        
        regions.forEach(region => {
            region.isChecked = state;
        });

        this.setState({
            selectedRegions: regions
        });
    }

    handleSelectedRegions(id){
        let regions = this.state.regions;
        
        regions.forEach(region => {
            region.isChecked = (id === region.id) ? !region.isChecked : region.isChecked;
        });

        this.setState({
            selectedRegions: regions
        });
        
    }
    
    handleSelectedColors(id){
        let colors = this.state.colors;
        
        colors.forEach(color => {
            color.isChecked = (id === color.id) ? !color.isChecked : color.isChecked;
        });
        this.setState({
            selectedColors: colors
        });
    }

    handleSelectedMetalness() {
        this.setState({
            selectedMetalness: !this.state.selectedMetalness
        });
    }
    
    handleSelectedDecors = selectedDecors => {
        this.setState({
            selectedDecors
        });
    }
    
    handleSelectedKinds = selectedKinds => {
        this.setState({
            selectedKinds,
            selectedKindsSub: null
        });
    }

    handleSelectedKindsSub = selectedKindsSub => {
        this.setState({
            selectedKindsSub
        });
    }

    handleSelectedProfiles = selectedProfiles => {
        this.setState({
            selectedProfiles
        });
    }

    handleSelectedHandles = selectedHandles => {
        this.setState({
            selectedHandles
        });
    }
    
    handleSelectedSurfaces = selectedSurfaces => {
        this.setState({
            selectedSurfaces
        });
    }

    handleTranslations = (lang, event) => {
        let translations = this.state.translations;
        translations[lang].name = event.target.value;

        this.setState({
            translations
        });
    }

    handleAcceptedFiles = (files, texture) => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size)
            })
        );
        texture.files = files;
        this.setState({ texture });
        console.log(this.state);
        this.upload(texture);
        
    };

    handleRemovedFiles = texture => {
        texture.files = [];
        texture.id = null;
        this.setState(texture);
    }
    
    handleTableSorting(key, keepDirection = false) {
        let materials = this.state.materials;
        let sorting = this.state.sorting;
        
        if (key === sorting.key && !keepDirection) {
            sorting.ascending = !sorting.ascending;
        } else {
            sorting.key = key;
            if (!keepDirection) {
                sorting.ascending = true;   
            }
        }
        this.setState({ sorting });
        
        switch (key) {
            case 'producer':
                materials.sort((a, b) => {
                    if (sorting.ascending) {
                        return a.producerName.localeCompare(b.producerName);
                    } else {
                        return b.producerName.localeCompare(a.producerName);
                    }
                });
                break;
            case 'kind':
                materials.sort((a, b) => {
                    if (sorting.ascending) {
                        return a.kind.localeCompare(b.kind);
                    } else {
                        return b.kind.localeCompare(a.kind);
                    }
                });
                break;
            case 'surface':
                materials.sort((a, b) => {
                    if (sorting.ascending) {
                        return a.surface.localeCompare(b.surface);
                    } else {
                        return b.surface.localeCompare(a.surface);
                    }
                });
                break;
            case 'status':
                materials.sort((a, b) => {
                    if (a.is_available < b.is_available) {
                        return sorting.ascending ? -1 : 1;
                    }
                    if (a.is_available > b.is_available) {
                        return sorting.ascending ? 1 : -1;
                    }
                    return 0;
                });
                break;
            case 'name':
                materials.sort((a, b) => {
                    if (sorting.ascending) {
                        return a.name_app.localeCompare(b.name_app);
                    } else {
                        return b.name_app.localeCompare(a.name_app);
                    }
                });
                break;
            case 'code':
                materials.sort((a, b) => {
                    if (sorting.ascending) {
                        return a.name.localeCompare(b.name);
                    } else {
                        return b.name.localeCompare(a.name);
                    }
                });
                break;
            case 'date':
                materials.sort((a, b) => {
                    if (a.updated_at < b.updated_at) {
                        return sorting.ascending ? -1 : 1;
                    }
                    if (a.updated_at > b.updated_at) {
                        return sorting.ascending ? 1 : -1;
                    }
                    return 0;
                });
                break;
            default:
                break;
        }

        
        this.setState({ materials });
        console.log(this.state.sorting);
    }

    sortingHeader(key, name) {
        return (
            <button
              type="button"
                style={{ paddingLeft: 0 }}
                onClick={() => this.handleTableSorting(key) }
              className="btn-outline btn-big btn waves-effect waves-light"
              aria-label="Sort"
            >
            <strong>{ name }</strong> &nbsp;
            { this.state.sorting.key === key ? 
                this.state.sorting.ascending ?
                    <i className="bx bx-caret-down"></i> : <i className="bx bx-caret-up"></i>
                : null }
            </button>
        );
    }
    
    handleEnterAdvancedMode = () => {
        this.setState({ advancedMode: true });
        let colorTexture = this.state.colorTexture1;
        colorTexture.files = [];
        let parametricTexture = this.state.normalTexture1;
        parametricTexture.files = [];
        
        this.setState({
            colorTexture,
            parametricTexture
        });
    }
    
    renderDropzone(texture, render, fileType) {
        if (!render) {
            return null;
        }
        return(
            <div className="col-lg-6 float-left p-2">
            <div className="col-lg-12 float-left border rounded p-4 bg-light">
                <label className="col-lg-12">{ texture.name }</label>
            <div className="col-lg-12 float-left">
            {texture.files.length === 0 &&
              <Dropzone
                multiple={false}
                accept={fileType}
                onDrop={ acceptedFiles => {
                            this.handleAcceptedFiles(acceptedFiles, texture)
                       }
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick mt-4"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                      </div>
                      <h4>Upuść plik tutaj</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
            }

              <div
                className="dropzone-previews"
                id="file-previews"
              >
                {texture.files.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            {
                                !this.state.advancedMode &&
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                            }
                          </Col>
                          <Col>
                            <p
                              className="text-muted font-weight-bold mb-0"
                            >
                              { texture.progress !== 100 && !texture.uploaded && !this.state.advancedMode ? 'Wysyłanie ' + texture.progress + '%' : '' }
                              { texture.progress === 100 && !texture.uploaded && !this.state.advancedMode ? 'Przetwarzanie... ' : '' }
                              { texture.uploaded || this.state.advancedMode ? f.name : ''}
                              { (texture.uploaded || (texture.files.length > 0 && this.state.advancedMode)) &&
                                <button
                                  type="button"
                                    onClick={() => this.handleRemovedFiles(texture)}
                                  className="close"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              }
                            </p>
                            {
                                texture.progress < 100 && !this.state.advancedMode &&
                                <Progress className="mt-2" style={{ height: "5px" }} color="success" value={texture.progress} ></Progress>
                            }

                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>

            </div>
            </div>
            </div>
        );
    }

    async handleSaveMaterial() {
            if (this.validateMaterial()) {
                console.log('validation ok');

                this.setState({ isSaving: true });

                if (this.state.advancedMode && !this.state.advancedTextureUpload.uploaded) {
                    this.uploadTextureSet();
                    return;
                }
                
                let data = {
                    id: this.state.materialEdit,
                    name: this.state.selectedName,
                    name_app: this.state.selectedNameApplication,
                    code4: this.state.selectedName4,
                    code10: this.state.selectedName10,
                    code18: this.state.selectedName18,
                    veneer: this.state.selectedVeneer,
                    kind: this.state.selectedKinds === undefined ? null : this.state.selectedKinds,
                    kind_sub: this.state.selectedKindsSub === undefined ? null : this.state.selectedKindsSub,
                    profile: this.state.selectedProfiles,
                    handle: this.state.selectedHandles,
                    decor: this.state.selectedDecors === undefined ? null : this.state.selectedDecors,
                    region: this.state.selectedRegions,
                    producer: this.state.selectedProducer,
                    category: this.state.selectedCategories,
                    color: this.state.selectedColors,
                    surface: this.state.selectedSurfaces === undefined ? null : this.state.selectedSurfaces,
                    thumbnail_texture: this.state.thumbnail.id,
                    color_texture: this.state.colorTexture1.id,
                    normal_texture: this.state.normalTexture1.id,
                    width: this.state.selectedWidth,
                    height: this.state.selectedHeight,
                    available: this.state.selectedAvailability,
                    is_metallic: this.state.selectedMetalness,
                    translations: this.state.translations
                }
                console.log(data);
                try {
                    var response = await postData(apiLink('admin/postData'), data);
                    console.log(response);
                    if (response.insert_status > 0){
                        toastr.success("Materiał został zapisany", "");
                        this.resetStates();
                        this.initializeView();
                    }
                } catch (error) {
                    console.log(error);
                    this.setState({ isSaving: false });
                    toastr.error("Błąd połączenia z serwerem", "");
                    //this.props.history.push('/logout');
                    return;
                }
            } else {
                console.log('validation failed');
            }
    }

    validateMaterial() {
        let isValid = true;
        
        var validationMessage = [];
        console.log(this.state);
        
        if (this.state.selectedName === null || !this.state.selectedName.length > 0) {
            isValid = false;
            validationMessage.push('Uzupełnij kod materiału');
        }
        if (this.state.selectedNameApplication === null || !this.state.selectedNameApplication.length > 0) {
            isValid = false;
            validationMessage.push('Uzupełnij nazwę materiału');
        }
        if (this.state.selectedKinds === null && this.state.materialType === materialTypes.STANDARD) {
            isValid = false;
            validationMessage.push('Uzupełnij rodzaj materiału');
        }
        if (this.state.selectedKindsSub === null && this.state.materialType === materialTypes.STANDARD) {
            isValid = false;
            validationMessage.push('Uzupełnij podrodzaj materiału');
        }
        if (this.state.selectedProfiles === null && this.state.materialType === materialTypes.PROFILE) {
            isValid = false;
            validationMessage.push('Uzupełnij system profilu');
        }
        if (this.state.selectedHandles === null && this.state.materialType === materialTypes.HANDLE) {
            isValid = false;
            validationMessage.push('Uzupełnij system uchwytu');
        }
        if (this.state.selectedSurfaces === null && this.state.materialType === materialTypes.STANDARD) {
            isValid = false;
            validationMessage.push('Uzupełnij typ powierzchni materiału');
        }
        if (this.state.selectedDecors === null && this.state.materialType === materialTypes.STANDARD) {
            isValid = false;
            validationMessage.push('Uzupełnij dekor materiału');
        }
        if (this.state.selectedRegions === null || !this.state.selectedRegions.length > 0) {
            isValid = false;
            validationMessage.push('Uzupełnij regiony materiału');
        }
        if (this.state.selectedProducer === null && this.state.materialType === materialTypes.STANDARD) {
            isValid = false;
            validationMessage.push('Uzupełnij producenta materiału');
        }
        if (this.state.selectedCategories === null || !this.state.selectedCategories.length > 0) {
            isValid = false;
            validationMessage.push('Uzupełnij kategorie materiału');
        }
        if (this.state.selectedColors === null || !this.state.selectedColors.length > 0) {
            isValid = false;
            validationMessage.push('Uzupełnij kolory materiału');
        }
        if (this.state.selectedWidth === null || !this.state.selectedWidth > 0) {
            isValid = false;
            validationMessage.push('Uzupełnij szerokość materiału');
        }
        if (this.state.selectedHeight === null || !this.state.selectedHeight > 0) {
            isValid = false;
            validationMessage.push('Uzupełnij wysokość materiału');
        }
        
        if (this.state.advancedMode) {
            if (this.state.colorTexture1 === null || !this.state.colorTexture1.files.length > 0) {
                isValid = false;
                validationMessage.push('Uzupełnij mapę koloru materiału');
            }
            if (this.state.normalTexture1 === null || !this.state.normalTexture1.files.length > 0) {
                isValid = false;
                validationMessage.push('Uzupełnij mapę parametryczną materiału');
            }
            if (this.state.colorTextureAstcSmall === null || !this.state.colorTextureAstcSmall.files.length > 0) {
                isValid = false;
                validationMessage.push('Uzupełnij mapę koloru ASTC SMALL');
            }
            
            if (this.state.colorTextureAstcMedium === null || !this.state.colorTextureAstcMedium.files.length > 0) {
                isValid = false;
                validationMessage.push('Uzupełnij mapę koloru ASTC MEDIUM');
            }
            
            if (this.state.colorTextureAstcLarge === null || !this.state.colorTextureAstcLarge.files.length > 0) {
                isValid = false;
                validationMessage.push('Uzupełnij mapę koloru ASTC LARGE');
            }
            
            if (this.state.paramTextureAstcSmall === null || !this.state.paramTextureAstcSmall.files.length > 0) {
                isValid = false;
                validationMessage.push('Uzupełnij mapę parametryczną ASTC SMALL');
            }
            
            if (this.state.paramTextureAstcMedium === null || !this.state.paramTextureAstcMedium.files.length > 0) {
                isValid = false;
                validationMessage.push('Uzupełnij mapę parametryczną ASTC MEDIUM');
            }
            
            if (this.state.paramTextureAstcLarge === null || !this.state.paramTextureAstcLarge.files.length > 0) {
                isValid = false;
                validationMessage.push('Uzupełnij mapę parametryczną ASTC LARGE');
            }
        }
        else {
            if (this.state.colorTexture1 === null || !this.state.colorTexture1.files.length > 0 || !this.state.colorTexture1.uploaded) {
                isValid = false;
                validationMessage.push('Uzupełnij mapę koloru materiału');
            }
            if (this.state.normalTexture1 === null || !this.state.normalTexture1.files.length > 0 || !this.state.normalTexture1.uploaded) {
                isValid = false;
                validationMessage.push('Uzupełnij mapę parametryczną materiału');
            }
        }
    
        this.setState({ validationMessage: validationMessage });
        
        return isValid;
    }

    upload(texture) {
        
        if (this.state.advancedMode && texture !== this.state.thumbnail) {
            return;
        }
        
        const config = {
            onUploadProgress: progressEvent => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                texture.progress = percentCompleted;
                this.setState({
                    texture
                });
            }
        }
        texture.uploaded = false;
        let data = new FormData()
        data.append('file', texture.files[0])
        let sessionData = JSON.parse(sessionStorage.getItem('authUser'));
        data.append('token', sessionData.user_token);
        data.append('astc', texture.astc);
        data.append('is_thumbnail', texture === this.state.thumbnail);
        console.log('is thumbnail = ' + texture === this.state.thumbnail);

        axios.post(apiLink('admin/uploadTexture'), data, config)
            .then(res => {
                console.log(res);
                if (res.data.success === true) {
                    texture.uploaded = true;
                    texture.id = res.data.data;
                    this.setState({
                        texture
                    });
                    toastr.success("Textura została zapisana", "");
                }
            })
            .catch(err => { 
                console.log(err.response);
                toastr.error("Błąd połączenia z serwerem", "");
            })
    }

    uploadTextureSet() {
        this.setState({ savingMaterial: true });
        var textureSet = this.state.advancedTextureUpload
        const config = {
            onUploadProgress: progressEvent => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                console.log(percentCompleted);
                textureSet.progress = percentCompleted;
                this.setState({
                    textureSet
                });
            }
        }
        textureSet.uploaded = false;
        let data = new FormData();
        let sessionData = JSON.parse(sessionStorage.getItem('authUser'));
        console.log(sessionData.user_token);
        data.append('token', sessionData.user_token);
        data.append('colorTextureJpg', this.state.colorTexture1.files[0]);
        data.append('parametricTextureJpg', this.state.normalTexture1.files[0]);
        data.append('colorTextureASTC_small', this.state.colorTextureAstcSmall.files[0]);
        data.append('parametricTextureASTC_small', this.state.paramTextureAstcSmall.files[0]);
        data.append('colorTextureASTC_medium', this.state.colorTextureAstcMedium.files[0]);
        data.append('parametricTextureASTC_medium', this.state.paramTextureAstcMedium.files[0]);
        data.append('colorTextureASTC_large', this.state.colorTextureAstcLarge.files[0]);
        data.append('parametricTextureASTC_large', this.state.paramTextureAstcLarge.files[0]);

        axios.post(apiLink('admin/uploadTextureSet'), data, config)
            .then(res => {
                console.log(res.data.success === true);
                console.log(res);
                if (res.data.success === true) {
                    console.log('upload');
                    textureSet.uploaded = true;
                    console.log('1');
                    textureSet.color_id = res.data.data.color_id;
                    textureSet.parametric_id = res.data.data.parametric_id;
                    console.log('2');
                    let colorTexture = this.state.colorTexture1;
                    colorTexture.id = textureSet.color_id;
                    let parametricTexture = this.state.normalTexture1;
                    parametricTexture.id = textureSet.parametric_id;
                    console.log('3');
                    this.setState({
                        textureSet,
                        colorTexture,
                        parametricTexture
                    });
                    this.setState({ savingMaterial: false });
                    this.handleSaveMaterial();
                    toastr.success("Textury zostały zapisane", "");
                }
            })
            .catch(err => { 
                console.log(err.response);
                toastr.error("Błąd połączenia z serwerem", "");
            })
    }

    deleteMaterial(materialId) {
        let sessionData = JSON.parse(sessionStorage.getItem('authUser'));
        let data = {
            token: sessionData.user_token,
            material_id: materialId
        }

        axios.post(apiLink('admin/removeMaterial'), data)
            .then(res => {
                console.log(res);
                if (res.data.success === true) {
                    this.resetStates();
                    this.initializeView();
                    toastr.success("Materiał został usunięty", "");
                    };
                })
                .catch(err => { 
                    console.log(err.response);
                    toastr.error("Błąd połączenia z serwerem", "");
                })
    }

    editMaterial(materialID) {
        let mat = this.state.materials.find(m => m.id === materialID);
        console.log(mat);
        
        let regions = this.state.selectedRegions;
        regions.forEach(region => {
            region.isChecked = mat.region.find(c => c.region_id === region.id) !== undefined;
        })
        
        let colors = this.state.selectedColors;
        colors.forEach(color => {
            color.isChecked = mat.color.find(c => c.color_id === color.id) !== undefined;
        })
        
        let selectedCategories = this.state.categories[0].options.filter(o => mat.category.find(c => c.category_id === o.id));
        let selectedProfiles = this.state.profiles[0].options.filter(o => mat.profile.find(c => c.profile_id === o.id));
        let selectedHandles = this.state.handles[0].options.filter(o => mat.handle.find(c => c.handle_id === o.id));

        mat.translations = mat.translations === null ?
            {} : typeof mat.translations === 'string' ?
                JSON.parse(mat.translations) : mat.translations;

        for (const lang in tralsnationLanguage) {
            if (mat.translations[lang] === undefined) {
                mat.translations[lang] = { name: mat.name_app };
            }
        }

        console.log(mat.translations);

        this.setState({
            selectedName: mat.name,
            selectedNameApplication: mat.name_app,
            selectedName4: mat.code4 === null ? '' : mat.code4,
            selectedName10: mat.code10 === null ? '' : mat.code10,
            selectedName18: mat.code18 === null ? '' : mat.code18,
            selectedVeneer: mat.veneer,
            selectedKinds: this.state.kinds[0].options.find(o => o.id === mat.kind_id),
            selectedKindsSub: this.state.kindsSub[0].options.find(o => o.id === mat.kind_sub_id),
            selectedSurfaces: this.state.surfaces[0].options.find(o => o.id === mat.surface_id),
            selectedDecors: this.state.decors[0].options.find(o => o.id === mat.decor_id),
            selectedProducer: mat.producer[0] === undefined ? null : this.state.producers[0].options.find(o => o.id === mat.producer[0].producer_id),
            selectedCategories: selectedCategories,
            selectedProfiles: selectedProfiles,
            selectedHandles: selectedHandles,
            selectedRegions: regions,
            selectedColors: colors,
            selectedWidth: mat.width,
            selectedHeight: mat.height,
            selectedMetalness: mat.is_metallic === '0' ? false : true,
            selectedAvailability: mat.is_available === '0' ? false : true,
            translations: mat.translations,
            colorTexture1: { name: 'Mapa koloru', uploaded: true, progress: 100, files: [{preview: apiLink('textures/')+mat.color_texture[0].small, name: 'Mapa koloru'}], id: mat.color_texture_id },
            normalTexture1: { name: 'Mapa parametrów', uploaded: true, progress: 100, files: [{preview: apiLink('textures/')+mat.parametric_texture[0].small, name: 'Mapa parametrów'}], id: mat.parametric_texture_id },
            thumbnail: { name: 'Miniatura JPG (opcjonalnie)', uploaded: false, progress: 0, files: [], id: null, astc: null},
            toggleAddMaterial: true,
            materialEdit: mat.id,
            advancedMode: false,
            materialType: materialTypes.STANDARD,
            validationMessage: []
        });

        if (mat.thumbnail_texture_id !== null) {
            let thumbnail = this.state.thumbnail;
            thumbnail.uploaded = true;
            thumbnail.progress = 100;
            thumbnail.files = [{preview: apiLink('textures/')+mat.thumbnail_texture[0].small, name: 'Miniatura'}];
            thumbnail.id = mat.thumbnail_texture_id;
            this.setState({
                thumbnail
            });
        }

        if (selectedCategories!== null && selectedCategories.filter(c => c.name === 'profil').length > 0) {
            this.setState({ materialType: materialTypes.PROFILE });
        }

        if (selectedCategories!== null && selectedCategories.filter(c => c.name === 'uchwyt').length > 0) {
            this.setState({ materialType: materialTypes.HANDLE });
        }

        if (selectedCategories!== null && selectedCategories.filter(c => c.name === 'system orto').length > 0) {
            this.setState({ materialType: materialTypes.ORTO });
        }

    }

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    render() {
        const { selectedProducer } = this.state;
        const { selectedCategories } = this.state;
        const { selectedRegions } = this.state;
        const { selectedKinds } = this.state;
        const { selectedKindsSub } = this.state;
        const { selectedColors } = this.state;
        const { selectedDecors } = this.state;
        const { selectedSurfaces } = this.state;
        const { selectedProfiles } = this.state;
        const { selectedHandles } = this.state;
        const filteredKindsSub = [{ 'options': this.state.selectedKinds === null || this.state.selectedKinds === undefined ? null : this.state.kindsSub[0].options.filter(e => e.kind_id === this.state.selectedKinds.id) }];

        return (
            <React.Fragment>
                <div className="page-title-box">
                    <h4 className="mb-0">
                    Materiały
                    </h4>
                </div>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">
                            <div className="float-left">
                                <Input
                                    type="text"
                                    style={{width: '300px'}}
                                    className="float-left"
                                    placeholder="Szukaj..."
                                    value={ this.state.search }
                                    onChange={ event => { this.setState({search: event.target.value}) } }
                                />
                                <div className="ml-2 float-left">
                                    <Button type="button" color="secondary" size="big" className="waves-effect waves-light mb-4 float-left" onClick={this.handleSearch}>
                                        <i className="bx bx-search big font-size-18 align-middle"></i>
                                    </Button>
                                    <Button type="button" color="secondary" size="big" className="waves-effect waves-light mb-4 ml-2 float-left" onClick={this.handleClearSearch}>
                                        <i className="bx bx-x big font-size-18 align-middle"></i>
                                    </Button>
                                </div>
                            </div>
                            <Button type="button" color="success" size="big" className="waves-effect waves-light float-right mb-4 ml-2" onClick={ () =>  {this.toggleAddMaterial(materialTypes.HANDLE)} }>
                                Dodaj Uchwyt
                            </Button>
                            <Button type="button" color="success" size="big" className="waves-effect waves-light float-right mb-4 ml-2" onClick={ () =>  {this.toggleAddMaterial(materialTypes.ORTO)} }>
                                Dodaj System Orto
                            </Button>
                            <Button type="button" color="success" size="big" className="waves-effect waves-light float-right mb-4 ml-2" onClick={ () =>  {this.toggleAddMaterial(materialTypes.PROFILE)} }>
                                Dodaj profil
                            </Button>
                            <Button type="button" color="success" size="big" className="waves-effect waves-light float-right mb-4" onClick={ () =>  {this.toggleAddMaterial(materialTypes.STANDARD)} }>
                                Dodaj materiał
                            </Button>
                        </CardTitle>
                        <div className="table-responsive">
                            <table className="table table-centered table-nowrap mb-0">
                                <thead className="thead-light">
                                    <tr>
                                        <th style={{ width: "20px" }}>
                                            ID
                                        </th>
                                        <th></th>
                                        <th>{ this.sortingHeader('code', 'Kod') }</th>
                                        <th>{ this.sortingHeader('name', 'Nazwa') }</th>
                                        <th>{ this.sortingHeader('producer', 'Producent') }</th>
                                        <th>{ this.sortingHeader('kind', 'Rodzaj') }</th>
                                        <th>{ this.sortingHeader('surface', 'Powierzchnia') }</th>
                                        <th>{ this.sortingHeader('status', 'Status') }</th>
                                        <th>{ this.sortingHeader('date', 'Data modyfikacji') }</th>
                                        <th>Akcje</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.materials.map((material, key) =>
                                            <tr key={"_tr_" + key} className={ material.filtered ? "" : "d-none" }>
                                                <td>
                                                    {material.id}
                                                </td>
                                                <td>
                                                    <img
                                                      width="30"
                                                      height="30"
                                                      className="rounded border"
                                                      src={apiLink('textures/')+material.color_texture[0].small}
                                                      alt={material.name}
                                                    />
                                                </td>
                                                <td><strong>{ material.name }</strong></td>
                                                <td>{material.name_app}</td>
                                                <td>
                                                    {material.producerName}
                                                </td>
        
                                                <td>
                                                    {material.kind}
                                                </td>
        
                                                <td>
                                                    {material.surface}
                                                </td>
        
                                                <td>
                                                    { material.is_available === '1' ? <span className="badge badge badge-soft-success font-size-12 badge-pill">Dostępny</span> : <span className="badge badge-soft-secondary font-size-12 badge-pill">Niedostępny</span> }
                                                </td>
                                                <td>
                                                    { material.updated_at }
                                                </td>
                                                <td>
                                                    <div className="btn-group">
                                                    <Button type="button" color="primary" size="sm" className="waves-effect waves-light"
                                                    onClick={() => { this.editMaterial(material.id) }}
                                                    >
                                                        <i className="bx bx-edit font-size-14 align-middle"></i>
                                                    </Button>
                                                    {/* <Button type="button" color="danger" size="sm" className="waves-effect waves-light" onClick={() => { this.setState({ confirmDeletion: true, confirmDeletionMaterialID: material.id }) }}>
                                                        <i className="bx bx-trash font-size-14 align-middle"></i>
                                                    </Button> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
                <Modal
                    size="xl"
                  isOpen={this.state.toggleAddMaterial}
                    scrollable={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                    {
                        this.state.materialEdit !== null ? <span>Edytuj { this.state.materialType }</span> : <span>Dodaj { this.state.materialType }</span>
                    }
                    {
                        this.state.advancedMode &&
                        <span> - tryb zaawansowany</span>
                    }
                  </h5>
                    <button
                      type="button"
                      onClick={() =>
                        this.setState({ toggleAddMaterial: false })
                      }
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="card-title mb-4">
                        Podstawowe informacje
                    
                        <div className="custom-control custom-switch mb-2 float-right" dir="ltr">
                            <p className="float-left" style={ {marginRight: 45, fontWeight: 'normal'} }>{ this.state.selectedAvailability ? 'Dostępny' : 'Niedostępny' }</p>
                            <input type="checkbox" className="custom-control-input float-right" id="customSwitch1" checked={ this.state.selectedAvailability } onChange={() => false}/>
                            <label className="custom-control-label" htmlFor="customSwitch1" onClick={(e) => { this.setState({ selectedAvailability: !this.state.selectedAvailability }) }}></label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <FormGroup>
                                <Label>Kod materiału</Label>
                                <Input
                                    type="text"
                                    value={ this.state.selectedName }
                                    onChange={this.handleSelectedName}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Nazwa materiału</Label>
                                <Input
                                    type="text"
                                    value={ this.state.selectedNameApplication }
                                    onChange={this.handleSelectedNameApplication}
                                />
                            </FormGroup>
                            { this.state.materialType === materialTypes.STANDARD ? (
                            <FormGroup>
                                <Label>Kod materiału ( 18 mm )</Label>
                                <Input
                                    type="text"
                                    value={ this.state.selectedName18 }
                                    onChange={this.handleSelectedName18}
                                />
                            </FormGroup>
                            ) : null }
                            { this.state.materialType === materialTypes.STANDARD ? (
                            <FormGroup>
                                <Label>Kod materiału ( 10mm )</Label>
                                <Input
                                    type="text"
                                    value={ this.state.selectedName10 }
                                    onChange={this.handleSelectedName10}
                                />
                            </FormGroup>
                            ) : null }
                            { this.state.materialType === materialTypes.STANDARD ? (
                            <FormGroup>
                                <Label>Kod materiału ( 4mm )</Label>
                                <Input
                                    type="text"
                                    value={ this.state.selectedName4 }
                                    onChange={this.handleSelectedName4}
                                />
                            </FormGroup>
                            ) : null }
                        </div>
                        <div className="col-lg-6">
                            { this.state.materialType === materialTypes.STANDARD ? (
                            <FormGroup>
                                <Label>Producent</Label>
                                    <Select
                                        placeholder="Wybierz"
                                        value={selectedProducer}
                                        onChange={this.handleSelectedProducer}
                                        options={this.state.producers}
                                        getOptionValue={option => option['id']}
                                        getOptionLabel={option => option['name']}
                                        classNamePrefix="select2-selection"
                                    />
                            </FormGroup>
                            ) : null }
                            { this.state.materialType === materialTypes.STANDARD ? (
                            <FormGroup>
                                <Label>Okleina</Label>
                                <Input
                                    type="text"
                                    value={ this.state.selectedVeneer }
                                    onChange={this.handleSelectedVeneer}
                                />
                            </FormGroup>
                            ) : null }
                            <FormGroup>
                                <Label>Kategoria</Label>
                                    <Select
                                        placeholder="Wybierz"
                                        isMulti={true}
                                        value={selectedCategories}
                                        isDisabled={ this.state.selectedCategories !== null && this.state.selectedCategories.filter( c => c.name === 'profil' || c.name === 'system orto' || c.name === 'uchwyt').length > 0 ? true : false }
                                        onChange={this.handleSelectedCategory}
                                        options={this.state.categories[0].options.filter( c => c.name !== 'profil' && c.name !== 'system orto' && c.name !== 'uchwyt')}
                                        getOptionValue={option => option['id']}
                                        getOptionLabel={option => option['name']}
                                        classNamePrefix="select2-selection"
                                    />
                            </FormGroup>

                            { this.state.materialType === materialTypes.PROFILE ? (
                            <FormGroup>
                                <Label>System profilu</Label>
                                    <Select
                                        placeholder="Wybierz"
                                        isMulti={true}
                                        value={selectedProfiles}
                                        onChange={this.handleSelectedProfiles}
                                        options={this.state.profiles}
                                        getOptionValue={option => option['id']}
                                        getOptionLabel={option => option['name']}
                                        classNamePrefix="select2-selection"
                                    />
                            </FormGroup>
                             ) : null }

                            { this.state.materialType === materialTypes.HANDLE ? (
                            <FormGroup>
                                <Label>System uchwytu</Label>
                                    <Select
                                        placeholder="Wybierz"
                                        isMulti={true}
                                        value={selectedHandles}
                                        onChange={this.handleSelectedHandles}
                                        options={this.state.handles}
                                        getOptionValue={option => option['id']}
                                        getOptionLabel={option => option['name']}
                                        classNamePrefix="select2-selection"
                                    />
                            </FormGroup>
                             ) : null }
                        </div>
                    </div>
                    <hr></hr>

                    <div className="card-title  mb-4">Tłumaczenia</div>
                    <div className="row">
                        <div className="col-lg-6">
                            { this.state.translations !== null ?
                            Object.keys(this.state.translations).map(t => (
                                <div>
                                <FormGroup>
                                    <Label>{ tralsnationLanguage[t] }</Label>
                                    <Input
                                        type="text"
                                        value={ this.state.translations[t].name === undefined ? '' : this.state.translations[t].name }
                                        onChange={(e) => this.handleTranslations(t, e)}
                                    />
                                </FormGroup>
                                </div>
                            )) : null }
                        </div>
                    </div>

                    <hr></hr>
                    <div className="card-title  mb-4">Dostępność</div>
                    <div className="mb-4">
                        <Button type="button" color="secondary" size="sm" className="waves-effect waves-light btn-rounded mr-2"
                        onClick={() => { this.handleSelectAllRegions(true) }}
                        >
                            Zaznacz wszystkie
                        </Button>
                        <Button type="button" color="secondary" size="sm" className="waves-effect waves-light btn-rounded"
                        onClick={() => { this.handleSelectAllRegions(false) }}
                        >
                            Odznacz wszystkie
                        </Button>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                
                                this.state.regions.map((region, key) =>
                                
                                    <div key={ region.id } className="custom-control custom-checkbox mb-3 col-lg-4 float-left">
                                        <input type="checkbox" className="custom-control-input" id={"regionID_" + (region.id)} onChange={() => false} checked={selectedRegions[key].isChecked} />
                                        <label className="custom-control-label" onClick={() => { this.handleSelectedRegions(region.id) }} >{ region.name }</label>
                                    </div>
                                )
                            }
                        </div>


                    </div>
                    <hr></hr>
                    <div className="card-title  mb-4">Cechy materiału</div>
                    <div className="row">
                    { this.state.materialType === materialTypes.STANDARD ? (
                        <div className="col-lg-6">
                            <FormGroup>
                                <Label>Rodzaj</Label>
                                    <Select
                                        placeholder="Wybierz"
                                        isMulti={false}
                                        value={selectedKinds}
                                        onChange={this.handleSelectedKinds}
                                        options={this.state.kinds}
                                        getOptionValue={option => option['id']}
                                        getOptionLabel={option => option['name']}
                                        classNamePrefix="select2-selection"
                                    />
                            </FormGroup>
                            <FormGroup>
                                <Label>Podrodzaj</Label>
                                    <Select
                                        placeholder="Wybierz"
                                        isMulti={false}
                                        value={selectedKindsSub}
                                        onChange={this.handleSelectedKindsSub}
                                        options={filteredKindsSub}
                                        getOptionValue={option => option['id']}
                                        getOptionLabel={option => option['name']}
                                        classNamePrefix="select2-selection"
                                    />
                            </FormGroup>
                            <FormGroup>
                                <Label>Powierzchnia</Label>
                                    <Select
                                        placeholder="Wybierz"
                                        isMulti={false}
                                        value={selectedSurfaces}
                                        onChange={this.handleSelectedSurfaces}
                                        options={this.state.surfaces}
                                        getOptionValue={option => option['id']}
                                        getOptionLabel={option => option['name']}
                                        classNamePrefix="select2-selection"
                                    />
                            </FormGroup>
                            <FormGroup>
                                <Label>Dekor</Label>
                                    <Select
                                        placeholder="Wybierz"
                                        isMulti={false}
                                        value={selectedDecors}
                                        onChange={this.handleSelectedDecors}
                                        options={this.state.decors}
                                        getOptionValue={option => option['id']}
                                        getOptionLabel={option => option['name']}
                                        classNamePrefix="select2-selection"
                                    />
                            </FormGroup>
                        </div>
                        ) : null }

                        { this.state.materialType !== materialTypes.ORTO && this.state.materialType !== materialTypes.HANDLE ? (
                        <div className="col-lg-6">
                            <Label>Kolor</Label>
                                <div className="col-lg-12">
                                    {
                                        this.state.colors.map((color, key) =>

                                            <div key={ color.id } className="custom-control custom-checkbox mb-3 col-lg-4 float-left">
                                                <input type="checkbox" className="custom-control-input" id={"colorID_" + (color.id)} onChange={() => false} checked={selectedColors[key].isChecked} />
                                                <label className="custom-control-label" onClick={() => { this.handleSelectedColors(color.id) }} >{ color.name }</label>
                                            </div>
                                        )
                                    }
                                </div>
                        </div>
                        ) : null }

                        <div className="col-lg-6">
                            <Label>Dodatkowe parametry</Label>
                                <div className="col-lg-12">
                                    <div className="custom-control custom-checkbox mb-3 col-lg-12 float-left">
                                       <input type="checkbox" className="custom-control-input" onChange={() => false} checked={this.state.selectedMetalness} />
                                        <label className="custom-control-label" onClick={() => { this.handleSelectedMetalness() }} >materiał jest metaliczny</label>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="card-title  mb-4">Tekstury materiału</div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="float-right col-lg-6">
                                <div className="col-lg-12">
                                    <FormGroup>
                                        <Label>Szerokość w mm</Label>
                                        <Input
                                            type="number"
                                            value={ this.state.selectedWidth }
                                            onChange={(e) => { this.setState({ selectedWidth: e.target.value }) }}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-12">
                                    <FormGroup>
                                        <Label>Wysokość w mm</Label>
                                        <Input
                                            type="number"
                                            value={ this.state.selectedHeight }
                                            onChange={(e) => { this.setState({ selectedHeight: e.target.value }) }}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            
                            {this.renderDropzone(this.state.thumbnail, true, '.jpg')}
                        </div>
                        <div className="col-lg-12">

                            {this.renderDropzone(this.state.colorTexture1, true, '.jpg')}
                            {this.renderDropzone(this.state.normalTexture1, true, '.jpg')}

                            {
                                this.state.advancedMode &&
                                    
                                <div className="col-lg-12 float-left">
                                    <hr className="col-lg-12"></hr>
                                    <Label className="col-lg-12">Tekstury SMALL</Label>
                                </div>

                            }

                            {this.renderDropzone(this.state.colorTextureAstcSmall, this.state.advancedMode, '.astc')}
                            {this.renderDropzone(this.state.paramTextureAstcSmall, this.state.advancedMode, '.astc')}

                            {
                                this.state.advancedMode &&
                                    
                                <div className="col-lg-12 float-left">
                                    <hr className="col-lg-12"></hr>
                                    <Label className="col-lg-12">Tekstury MEDIUM</Label>
                                </div>

                            }

                            {this.renderDropzone(this.state.colorTextureAstcMedium, this.state.advancedMode, '.astc')}
                            {this.renderDropzone(this.state.paramTextureAstcMedium, this.state.advancedMode, '.astc')}

                            {
                                this.state.advancedMode &&
                                    
                                <div className="col-lg-12 float-left">
                                    <hr className="col-lg-12"></hr>
                                    <Label className="col-lg-12">Tekstury LARGE</Label>
                                </div>

                            }

                            {this.renderDropzone(this.state.colorTextureAstcLarge, this.state.advancedMode, '.astc')}
                            {this.renderDropzone(this.state.paramTextureAstcLarge, this.state.advancedMode, '.astc')}

                        </div>
                    </div>
                    
                  </div>
                  <div className="modal-footer">
                    {
                      this.state.savingMaterial ?
                        <div className="col-lg-12">
                        <span className="">Zapisywanie... </span>
                        <Progress className="" style={{ height: "5px" }} color="success" value={this.state.advancedTextureUpload.progress} ></Progress>
                        </div>
                        :
                        <div>
                        {
                            this.state.materialEdit !== null ?
                            <button type="button" className="btn btn-success waves-effect waves-light" onClick={this.handleSaveMaterial}>
                                  Zaktualizuj
                              </button>
                             :
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light"
                                onClick={this.handleSaveMaterial}
                            >
                              Zapisz jako nowy
                            </button>
                        }
                        {
                            !this.state.advancedMode &&
                            <button
                              type="button"
                              className="btn btn-danger waves-effect waves-light ml-2"
                                onClick={() => { this.handleEnterAdvancedMode() }}
                            >
                              Tryb zaawansowany
                            </button>
                        }
                        </div>
                    }
                  </div>

                </Modal>
                {this.state.confirmDeletion ? (
                    <SweetAlert
                        title="Czy usunąć materiał?"
                        warning
                        showCancel
                        confirmBtnText="Usuń"
                        cancelBtnText="Anuluj"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="success"
                        onConfirm={() => {
                            this.setState({confirmDeletion: false});
                            this.deleteMaterial(this.state.confirmDeletionMaterialID);
                            }
                        }
                        onCancel={() =>
                            this.setState({confirmDeletion: false})
                        }
                    >
                        Akcja jest nieodwracalna.
                    </SweetAlert>
                ) : null}
                {this.state.validationMessage.length > 0 ? (
                    <SweetAlert
                        title="Niekompletne dane"
                        warning
                        confirmBtnText="OK"
                        confirmBtnBsStyle="danger"
                        onConfirm={() => {
                            this.setState({validationMessage: []});
                            }
                        }
                    >
                    {
                    this.state.validationMessage.map((info, key) => 
                        <div key={key} className="col-lg-12 small text-danger">{info}</div>
                    )
                    }
                        
                    </SweetAlert>
                ) : null}

                <Modal size="sm" isOpen={this.state.isSaving} scrollable={true} centered >
                    <div className="modal-body mt-3 text-center">
                                <p><span class="spinner-border spinner-border-sm mr-4 " role="status" aria-hidden="true"></span>Zapisywanie...</p>
                    </div>
                </Modal>

                <Modal size="sm" isOpen={this.state.isLoading} scrollable={true} centered >
                    <div className="modal-body mt-3 text-center">
                                <p><span className="spinner-border spinner-border-sm mr-4 " role="status" aria-hidden="true"></span>Wczytywanie...</p>
                    </div>
                </Modal>
            </React.Fragment>
        );   
    }
}

export default withRouter(Materials);
