function apiLink(link) {
    return 'https://aplikacja.komandor.pl/' + link;
    //return 'https://51.68.140.148/' + link;
}

function parseTimestamp(timestamp) {
    let updated = Date.parse(timestamp.replace(/-/gi, '/') + ' UTC');
    let date = new Date(updated);
    return (date.getYear() + 1900)  + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + "/" + ('0' + (date.getDate())).slice(-2) + ' ' + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2) + ":" + ('0' + date.getSeconds()).slice(-2);
}

function timestampToMicrotime(timestamp) {
    let date =  Date.parse(timestamp.replace(/-/gi, '/'));
    let microtime = new Date(date).getTime();
    return microtime;
}

function monthName(id) {
    let monthNames = ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paź', 'lis', 'gru'];
    return monthNames[id];
}

export { apiLink, parseTimestamp, timestampToMicrotime, monthName }