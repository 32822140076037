import React, {
    Component
} from "react";

import {
    Modal,
    Card,
    CardBody,
    CardTitle,
    FormGroup, InputGroup, Label, Progress, Button
} from "reactstrap";
import {
    withRouter
} from "react-router-dom";
import {
    getData,
} from "../../helpers/requests.js";
import { apiLink } from '../../helpers/commons.js';
import StackedColumnChart from "./StackedColumnChart";
import { timestampToMicrotime } from "../../helpers/commons.js"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { prototype } from "apexcharts";
import { CSVLink, CSVDownload } from "react-csv";

class Tools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csv: null,
            loadingProjects: false,
            loadingProjectsProgress: 0,
            isLoading: true,
            fromDate: null,
            toDate: null,
            materials: [],
            translations: {
                Minimalist: 'minimalistyczny',
                Modern: 'nowoczesny',
                Classic: 'klasyczny',
                Avangard: 'awangardowy',
                Unknown: 'nieznany',
                Medium: 'średni',
                Large: 'duży',
                Small: 'mały',
                ClothesShort : 'ubrania wiszące krótkie',
                ClothesFolded : 'ubrania złożone',
                ClothesLong : 'ubrania wiszące długie',
                Shoes : 'buty',
                Trousers : 'spodnie, spódnice',
                Underwear : 'bielizna, akcesoria',
                Towels : 'ręczniki, pościel',
                Bags : 'walizki torebki',
                Appliances : 'artykuły gospodarcze',
                Jewellery : 'biżuteria',
                Middle : 'wyśrodkowana',
                Full : 'zabudowa całej wnęki',
                RightSide : 'szafa dosunięta do prawej ściany',
                LeftSide : 'szafa dosunięta do lewej ściany',
                Niche : 'zabudowana',
                Free : 'wolnostojąca',
                Straight : 'prosta',
                Perpendicular : 'narożna',
                ThreeSided : 'typ U',
                DoubleSided : 'dwurzędowa',
                Standard : 'standard',
                Orto : 'orto',
                Regular : 'zwykła',
                DressingRoom : 'garderoba',
                Hinged: 'drzwi uchylne',
                Sliding: 'drzwi przesuwne',
                DoorInFrame: 'drzwi z ościeżnicą',
                Window: 'szklane witryny'
            }
        }

        this.handleDateFromChange = this.handleDateFromChange.bind(this);
        this.handleDateToChange = this.handleDateToChange.bind(this);
        this.handleRegionsChange = this.handleRegionsChange.bind(this);
        this.generateRaport = this.generateRaport.bind(this);
        this.findMaterialById = this.findMaterialById.bind(this);
    }

    componentDidMount() {
        this.initializeView();
    }
    
    async initializeView() {
        try {
            var materialsRequest = await getData(apiLink('admin/getData'), 'materials');
        } catch (error) {
            this.props.history.push('/logout');
            return;
        }

        let materials = materialsRequest.data.materials;
        let materialsObject = {}
        for (let o = 0; o < materials.length; o++) {
            materialsObject[materials[o].id] = materials[o];
        }
        materials = materialsObject;

        let materialKinds = materialsRequest.data.kinds;
        let materialKindsObject = {}
        for (let o = 0; o < materialKinds.length; o++) {
            materialKindsObject[materialKinds[o].id] = materialKinds[o];
        }
        materialKinds = materialKindsObject;

        try {
            var response = await getData(apiLink('admin/getData'), 'tools');
        } catch (error) {
            this.props.history.push('/logout');
            return;
        }
        response = response.data;

        let dateTo = new Date();
        dateTo.setHours(23, 59, 59, 999);
        let dateFrom = new Date();
        dateFrom.setDate(dateTo.getDate() - 6);
        dateFrom.setHours(0, 0, 0, 0);
        let dateStart = new Date("October 1, 2020 00:00:00");

        this.setState({
            projects: response.projects,
            filteredProjects: [],
            allFilteredCount: 0,
            fileterdValuationsCount: 0,
            projectsStats: null,
            projectsCount: response.projects.length,
            projectsWithContent: response.projects.filter( project => project.has_content === "1" ).length,
            mailings: response.mailings,
            fromDate: dateFrom,
            toDate: dateTo,
            startDate: dateStart,
            materials: materials,
            materialKinds: materialKinds,
            regions: [
                {id: "5", name: "Dolnośląskie", selected: true, code: 'dolnoslaskie'},
                {id: "6", name: "Kujawsko-Pomorskie", selected: true, code: 'kujawsko_pomorskie'},
                {id: "7", name: "Lubelskie", selected: true, code: 'lubelskie'},
                {id: "8", name: "Lubuskie", selected: true, code: 'lubuskie'},
                {id: "9", name: "Łódzkie", selected: true, code: 'lodzkie'},
                {id: "10", name: "Małopolskie", selected: true, code: 'malopolskie'},
                {id: "11", name: "Mazowieckie", selected: true, code: 'mazowieckie'},
                {id: "12", name: "Opolskie", selected: true, code: 'opolskie'},
                {id: "13", name: "Podkarpackie", selected: true, code: 'podkarpackie'},
                {id: "14", name: "Podlaskie", selected: true, code: 'podlaskie'},
                {id: "15", name: "Pomorskie", selected: true, code: 'pomorskie'},
                {id: "16", name: "Śląskie", selected: true, code: 'slaskie'},
                {id: "17", name: "Świętokrzyskie", selected: true, code: 'swietokrzyskie'},
                {id: "18", name: "Warmińsko-Mazurskie", selected: true, code: 'warminsko_mazurskie'},
                {id: "19", name: "Wielkopolskie - oddz. Kalisz", selected: true, code: 'wielkopolskie_oddz_kalisz'},
                {id: "20", name: "Wielkopolskie - oddz. Poznań", selected: true, code: 'wielkopolskie_oddz_poznan'},
                {id: "21", name: "Zachodniopomorskie", selected: true, code: 'zachodniopomorskie'},
                {id: "22", name: "Zagranica", selected: true, code: 'zagranica'}
            ],
            days: Math.ceil((dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24)),
            isLoading: false
        });

        console.log(this.state);
        
    }

    createEmptyStats() {
        return {
            appVersion: [],
            platform: [],
            regions: [],
            parsedType: [],
            doors: 0,
            lumi: 0,
            wardrobes: 0,
            wardrobesStats: {
                DoorType: [],
                WardrobeLayout: [],
                WardrobeSetup: [],
                WardrobeShape: [],
                WardrobeSystem: [],
                WardrobeConstruction: [],
                InteriorMaterial: [],
                BackMaterial: [],
                BaseMaterial: [],
                InteriorMaterialKind: [],
                BackMaterialKind: [],
                BaseMaterialKind: [],
                DoorMaterials: [],
                DoorMaterialKind: [],
                Modules: [],
                WardrobeStyle: [],
                WardrobeSize: [],
                WardrobePurposes: [],
                ProfileMaterials: []
            }
        };
    }

    generateRaport() {
        this.setState({loadingProjects: true});
        this.LoadProjectsData(); 
    }

    async LoadProjectsData() {
        let requestProjects = 500;
        let ids = [];
        let valuationsCount =  0;

        let projectsInTimeRange = this.state.projects.filter( e => this.state.toDate.getTime() > timestampToMicrotime(e.time_created) && this.state.fromDate.getTime() < timestampToMicrotime(e.time_created) );
        let projectsWithContent = projectsInTimeRange.filter( e => e.has_content === '1' );

        let filteredProjects = [];

        for (let i = 0; i < projectsWithContent.length; i++) {
            this.setState({loadingProjectsProgress: (i + 1) / projectsWithContent.length});

            ids.push(projectsWithContent[i].id);

            if (i === projectsWithContent.length - 1 || i%requestProjects === 0 && i > 0) {
                try {
                    var projectResponse = await getData(apiLink('admin/getData'), 'project', ids);
                    for (let p = 0; p < ids.length; p++) {
                        if (projectResponse.data.content[p].summary_info !== null) {
                            let parsedContent = JSON.parse(projectResponse.data.content[p].summary_info);

                            let platform = parsedContent.Platform;
                            if (platform === undefined) {
                                platform = 'brak danych';
                            }

                            parsedContent.Platform = platform;
                            if (platform === 'WindowsEditor') {
                                continue;
                            }

                            parsedContent.regionFilter = true;
                            if (parsedContent.IsSent){
                                valuationsCount++;
                            }

                            let savedData = JSON.parse(projectResponse.data.content[p].content);

                            parsedContent.region = savedData.WorkspaceData.Region;
                            parsedContent.WardrobeStyle = savedData.WorkspaceData.Questionnaire.WardrobeStyle;
                            parsedContent.WardrobeSize = savedData.WorkspaceData.Questionnaire.WardrobeSize;
                            parsedContent.Purposes = [];
                            savedData.WorkspaceData.Questionnaire.WardrobePurposes.forEach(purpose => {
                                parsedContent.Purposes.push(purpose);
                            });

                            let appVersion = parsedContent.AppVersion;
                            if (appVersion === undefined) {
                                appVersion = 'brak danych';
                            }
                            parsedContent.AppVersion = appVersion;

                            filteredProjects.push(parsedContent);
                        }
                    }
                    //this.state.projects.find(p => p.id == this.state.projects[i].id).content = projectResponse.data.content.content;
                } catch (error) {
                    console.error(error);
                }
                ids = [];
            }
        }

        this.setState({ filteredProjects: filteredProjects, allFilteredCount: projectsInTimeRange.length, fileterdValuationsCount: valuationsCount });
        this.setState({loadingProjects: false});

        this.filterByRegions();
    }

    collectStats() {
        let stats = this.createEmptyStats();
        let materials = this.state.materials;
        let parsedType = 'Nieznany';

        for (let i = 0; i < this.state.filteredProjects.length; i++) {
            
            if (!this.state.filteredProjects[i].regionFilter) {
                continue;
            }

            let project = this.state.filteredProjects[i];

            stats.appVersion.push(project.AppVersion);
            stats.platform.push(project.Platform);
            stats.regions.push(project.region);

            if (project.WardrobeConstruction === 'Doors')
            {
                stats.doors++;
                parsedType = "Fronty, " + this.translate(project.DoorType);
            } else
            if (project.WardrobeConstruction === 'Lumi')
            {
                stats.lumi++;
                parsedType = "System lumi, " + this.translate(project.LumiType);
            } else 
            {
                if (project.WardrobeConstruction === 'DressingRoom') {
                    parsedType = "Garderoba";
                } else {
                    parsedType = "Szafa, " + this.translate(project.DoorType) + ', ' + this.translate(project.WardrobeSetup);
                    stats.wardrobesStats.WardrobeStyle.push(project.WardrobeStyle);
                    if (project.WardrobeSetup === 'Niche') {
                        stats.wardrobesStats.WardrobeLayout.push(project.WardrobeLayout);
                    }
                }

                stats.wardrobes++;
                stats.wardrobesStats.DoorType.push(project.DoorType);
                stats.wardrobesStats.WardrobeShape.push(project.WardrobeShape);
                stats.wardrobesStats.WardrobeSystem.push(project.WardrobeSystem);
                stats.wardrobesStats.WardrobeConstruction.push(project.WardrobeConstruction);
                stats.wardrobesStats.InteriorMaterial.push(project.InteriorMaterial);
                stats.wardrobesStats.BackMaterial.push(project.BackMaterial);
                stats.wardrobesStats.BaseMaterial.push(project.BaseMaterial);
                
                project.Profiles.forEach(profile => {
                    if (profile.MaterialId !== '') {
                        stats.wardrobesStats.ProfileMaterials.push(profile.MaterialId);
                    }             
                });

                stats.wardrobesStats.InteriorMaterialKind.push(this.getMaterialKind(materials[project.InteriorMaterial].kind_id).name);

                if (project.BaseMaterial !== '') {
                    stats.wardrobesStats.BaseMaterialKind.push(this.getMaterialKind(materials[project.BaseMaterial].kind_id).name);
                }
                if (project.BackMaterial !== '') {
                    stats.wardrobesStats.BackMaterialKind.push(this.getMaterialKind(materials[project.BackMaterial].kind_id).name);
                }
                project.DoorsMaterials.forEach(doorMat => {
                    stats.wardrobesStats.DoorMaterials.push(doorMat);
                    stats.wardrobesStats.DoorMaterialKind.push(this.getMaterialKind(materials[doorMat].kind_id).name);
                });
                project.Modules.forEach(module => {
                    stats.wardrobesStats.Modules.push(module.MainModule);
                })
                if (project.WardrobeShape === 'Straight') {
                    stats.wardrobesStats.WardrobeSize.push(project.WardrobeSize);
                }
                project.Purposes.forEach(purpose => {
                    stats.wardrobesStats.WardrobePurposes.push(purpose);
                })
            }
            stats.parsedType.push(parsedType);
        }
        stats.wardrobesStats.DoorType = this.groupArray(stats.wardrobesStats.DoorType);
        stats.wardrobesStats.WardrobeLayout = this.groupArray(stats.wardrobesStats.WardrobeLayout);
        stats.wardrobesStats.WardrobeSetup = this.groupArray(stats.wardrobesStats.WardrobeSetup);
        stats.wardrobesStats.WardrobeShape = this.groupArray(stats.wardrobesStats.WardrobeShape);
        stats.wardrobesStats.WardrobeSystem = this.groupArray(stats.wardrobesStats.WardrobeSystem);
        stats.wardrobesStats.WardrobeConstruction = this.groupArray(stats.wardrobesStats.WardrobeConstruction);
        stats.wardrobesStats.InteriorMaterial = this.groupArray(stats.wardrobesStats.InteriorMaterial);
        stats.wardrobesStats.BackMaterial = this.groupArray(stats.wardrobesStats.BackMaterial);
        stats.wardrobesStats.BaseMaterial = this.groupArray(stats.wardrobesStats.BaseMaterial);
        stats.wardrobesStats.DoorMaterials = this.groupArray(stats.wardrobesStats.DoorMaterials);
        stats.wardrobesStats.Modules = this.groupArray(stats.wardrobesStats.Modules);
        stats.wardrobesStats.InteriorMaterialKind = this.groupArray(stats.wardrobesStats.InteriorMaterialKind);
        stats.wardrobesStats.BackMaterialKind = this.groupArray(stats.wardrobesStats.BackMaterialKind);
        stats.wardrobesStats.BaseMaterialKind = this.groupArray(stats.wardrobesStats.BaseMaterialKind);
        stats.wardrobesStats.DoorMaterialKind = this.groupArray(stats.wardrobesStats.DoorMaterialKind);
        stats.wardrobesStats.WardrobeStyle = this.groupArray(stats.wardrobesStats.WardrobeStyle);
        stats.wardrobesStats.WardrobeSize = this.groupArray(stats.wardrobesStats.WardrobeSize);
        stats.wardrobesStats.WardrobePurposes = this.groupArray(stats.wardrobesStats.WardrobePurposes);
        stats.wardrobesStats.ProfileMaterials = this.groupArray(stats.wardrobesStats.ProfileMaterials);

        stats.appVersion = this.groupArray(stats.appVersion);
        stats.platform = this.groupArray(stats.platform);
        stats.regions = this.groupArray(stats.regions);
        stats.parsedType = this.groupArray(stats.parsedType);

        this.setState({ projectsStats: stats });

        if (this.state.filteredProjects.length > 0) {
            this.parseCSVdata(stats);
        } else {
            this.setState({ csv: null });
        }
    }

    parseCSVdata() {
        let projectsStats = this.state.projectsStats;
        let data = [
            [['Typ'], projectsStats.parsedType],
            [['Wersja aplikacji'], projectsStats.appVersion],
            [['Platforma'], projectsStats.platform],
            [['Regiony'], projectsStats.regions],
            [['Szafy - styl'], projectsStats.wardrobesStats.WardrobeStyle],
            [['Szafy - rozmiar'], projectsStats.wardrobesStats.WardrobeSize],
            [['Szafy - przeznaczenie'], projectsStats.wardrobesStats.WardrobePurposes],
            [['Szafy zabudowane - układ'], projectsStats.wardrobesStats.WardrobeLayout],
            [['Top 10 materiały wnętrza'], projectsStats.wardrobesStats.InteriorMaterial, 'mat'],
            [['Top 10 materiały plecy'], projectsStats.wardrobesStats.BackMaterial, 'mat'],
            [['Top 10 materiały korpus'], projectsStats.wardrobesStats.BaseMaterial, 'mat'],
            [['Top 10 materiały drzwi'], projectsStats.wardrobesStats.DoorMaterials, 'mat'],
            [['Materiał wnętrza - typ'], projectsStats.wardrobesStats.InteriorMaterialKind],
            [['Materiał pleców - typ'], projectsStats.wardrobesStats.BackMaterialKind],
            [['Materiał korpus - typ'], projectsStats.wardrobesStats.BaseMaterialKind],
            [['Materiał drzwi - typ'], projectsStats.wardrobesStats.DoorMaterialKind],
            [['Materiał profili'], projectsStats.wardrobesStats.ProfileMaterials, 'profileMat'],
        ];

        let csv =
        [
            [this.state.fromDate.toDateString('YYYY-MM-dd') + ' - ' + this.state.toDate.toDateString('YYYY-MM-dd')],
            ['Projekty łącznie', this.state.allFilteredCount],
            ['Projekty zapisane', this.state.filteredProjects.length],
            ['Wyceny', this.state.fileterdValuationsCount],
        ];

        data.forEach(d => {
                csv.push(d[0]);
                if (d[2] === 'mat') {
                    let o = d[1];
                    for (let i = 0; i < 10; i++) {
                        let entry = o[i];
                        if (entry !== undefined) {
                            if (entry.length > 1) {
                                csv.push([this.findMaterialById(entry[0]).name_app, entry[1]]);
                            }
                        }
                    }
                } else if (d[2] === 'profileMat') {
                    let o = d[1];
                    for (let i = 0; i < o.length; i++) {
                        let entry = o[i];
                        if (entry !== undefined) {
                            if (entry.length > 1) {
                                csv.push([this.findMaterialById(entry[0]).profile_name, entry[1]]);
                            }
                        }
                    }
                }  else {
                    d[1].forEach(c => csv.push([this.translate(c[0]), c[1]]));
                }
            }
        )
        return csv;
    }

    filterByRegions() {
        for (let i = 0; i < this.state.filteredProjects.length; i++) {
            let project = this.state.filteredProjects[i];

            for (let r = 0; r < this.state.regions.length; r++) {
                if (project.region === this.state.regions[r].name) {
                    project.regionFilter = this.state.regions[r].selected;
                    break;
                }
            }

        }

        this.collectStats();
    }

    handleDateFromChange(date) {
        date.setHours(0, 0, 0, 0);
        this.setState({ fromDate: date, days: Math.ceil((this.state.toDate.getTime() - date.getTime()) / (1000 * 3600 * 24)) });
        this.setState({ csv: null, projectsStats: null, filteredProjects: [] });
    }

    handleDateToChange(date) {
        date.setHours(23, 59, 59, 999);
        this.setState({ toDate: date, days: Math.ceil((date.getTime() - this.state.fromDate.getTime()) / (1000 * 3600 * 24)) });
        this.setState({ csv: null, projectsStats: null, filteredProjects: [] });
    }

    handleRegionsChange(regionId) {
        let regions = this.state.regions;
        for (let i = 0; i < regions.length; i++) {
            if (regions[i].id === regionId) {
                regions[i].selected = !regions[i].selected;
                break;
            }
        }
        
        this.setState( { regions: regions }, this.filterByRegions() );
    }

    handleSelectAllRegions(state) {
        let regions = this.state.regions;
        
        regions.forEach(region => {
            region.selected = state;
        });

        this.setState({
            selectedRegions: regions
        });

        this.filterByRegions();
    }

    groupArray(a) {
        var initialValue = {};
        var reducer = function(a, b) {
          if (!a[b]) {
            a[b] = 1;
          } else {
            a[b] = a[b] + 1;
          }
          return a;
        }
        var result = a.reduce(reducer, initialValue)

        var sortableArray = [];
        for (let val in result) {
            sortableArray.push([val, result[val]]);
        }
        sortableArray.sort(function(a, b) {
            return b[1]-a[1];
        });
        return sortableArray;
    }

    findMaterialById(id) {
        let material = this.state.materials[id];

        if (material === undefined) {
            material = { name: 'nieznany', name_app: 'nieznany' }
            return material;
        }

        let kind = this.getMaterialKind(material.kind_id);
        if (kind === undefined) {
            kind = { name: 'nieznany' }
        }

        return { name_app: material.name_app + ' - ' + kind.name, profile_name: material.name + ' - ' + material.name_app };
    }

    getMaterialKind(id) {
        let kind = this.state.materialKinds[id];
        return kind;
    }

    translate(toTranslate) {
        let translation = this.state.translations[toTranslate];
        if (translation === undefined) {
            translation = toTranslate;
        }
        return translation;
    }

    renderObject(title, o) {
        return (
            <div className="col-xl-3" style={{ paddingBottom: "25px" }}>
            <Card style={{ height: "100%" }}>
                <CardBody>
                    <CardTitle className="mb-4">
                        <i className="bx bx-data font-size-24 align-middle mr-2 text-primary"></i>
                        { title }
                    </CardTitle>
                    <hr></hr>
                    <div>
                        {
                            o.length > 0 ?
                            o.map(([key, value]) =>
                            <div key={key} className="mt-2">
                            <span>{ this.translate(key) } : <strong>{ value }</strong></span>
                            </div>
                            )
                            :
                            <span>Brak danych</span>
                        }

                    </div>
                </CardBody>
            </Card>
        </div>
        )
    }

    renderMaterials(title, o, name, limit = 0) {
        if (limit > 0) {
            o = o.splice(0, limit);
        }
        return (
            <div className="col-xl-3" style={{ paddingBottom: "25px" }}>
            <Card style={{ height: "100%" }}>
                <CardBody>
                    <CardTitle className="mb-4">
                        <i className="bx bx-data font-size-24 align-middle mr-2 text-primary"></i>
                        { title }
                    </CardTitle>
                    <hr></hr>
                    <div>
                        {
                            o.length > 0 ?
                            o.map(([key, value]) =>
                            <div key={key} className="mt-2">
                            <span>{ this.findMaterialById(key)[name] }: <strong>{ value }</strong></span>
                            </div>
                            )
                            :
                            <span>Brak danych</span>
                        }

                    </div>
                </CardBody>
            </Card>
        </div>
        )
    }

    render() {

        if (!this.state.isLoading)
            {
             return (
            <React.Fragment>
                <div className="page-title-box">
                    <h4 className="mb-0">
                    Raporty
                    </h4>
                </div>
                <div className="row">
                <div className="col-xl-12">
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4">
                                    <i className="bx bx-time font-size-24 align-middle mr-2 text-primary"></i>
                                    Zakres czasowy
                                    { this.state.projectsStats !== null ?
                                         <CSVLink className="button btn btn-primary float-right ml-2" filename={"komandor-raport.csv"} data={this.parseCSVdata()}>Pobierz CSV</CSVLink>
                                    : null }
                                    <Button type="button" color="success" size="big" className="waves-effect waves-light float-right ml-2" onClick={ () =>  this.generateRaport() }>
                                        Generuj raport
                                    </Button>
                                </CardTitle>
                                <hr></hr>
                                <div className="col-3 float-left">
                                <FormGroup className="mb-0 float-left">
                                    <Label>Zakres od</Label>
                                    <InputGroup>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            selected={this.state.fromDate}
                                            className="form-control"
                                            onChange={this.handleDateFromChange}
                                            selectsStart
                                            startDate={this.state.fromDate}
                                            endDate={this.state.toDate}
                                            minDate={this.state.startDate}
                                            maxDate={this.state.toDate}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                </div>
                                <div className="col-3 float-left">
                                <FormGroup className="mb-0 float-left">
                                    <Label>Zakres do</Label>
                                    <InputGroup>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            selected={this.state.toDate}
                                            className="form-control"
                                            onChange={this.handleDateToChange}
                                            selectsEnd
                                            startDate={this.state.fromDate}
                                            endDate={this.state.toDate}
                                            minDate={this.state.fromDate}
                                            maxDate={new Date()}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-xl-12">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">
                                        <i className="bx bx-stats font-size-24 align-middle mr-2 text-primary"></i>
                                        Trend projektów { this.state.days } dni
                                    </CardTitle>
                                    <hr></hr>
                                    <StackedColumnChart data={ this.state.projects } from={ this.state.fromDate } to={ this.state.toDate } />
                                    <div>

                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    <div className="col-xl-12">
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4">
                                    <i className="bx bx-filter font-size-24 align-middle mr-2 text-primary"></i>
                                    Filtry
                                </CardTitle>
                                <hr></hr>
                                <div className="col-sm-12 mt-4">
                                    <Button type="button" color="secondary" size="sm" className="waves-effect waves-light btn-rounded mr-2"
                                        onClick={() => { this.handleSelectAllRegions(true) }}
                                        >
                                            Zaznacz wszystkie
                                        </Button>
                                        <Button type="button" color="secondary" size="sm" className="waves-effect waves-light btn-rounded"
                                        onClick={() => { this.handleSelectAllRegions(false) }}
                                        >
                                            Odznacz wszystkie
                                    </Button>
                                    <div className="mt-4">
                                    {
                                        this.state.regions.map((region) =>
                                        
                                        <div key={ region.id } className="custom-control custom-checkbox mb-3 col-lg-3 float-left" >
                                            <input type="checkbox" className="custom-control-input" id={"regionID_" + (region.id)} disabled={false} checked={ region.selected } onChange={ () => null } />
                                            <label className="custom-control-label" onClick={() => this.handleRegionsChange(region.id)}>{ region.name }</label>
                                        </div>
                                    )
                                    }
                                    </div>
                                </div>
                                
                            </CardBody>
                        </Card>
                    </div>

                    {
                        this.state.projectsStats !== null ?
                    <div className="col-xl-12 row">
                        <div className="col-xl-12 mt-4">
                            <h4 className="mb-4">
                                Raport
                            </h4>
                        </div>
                        <div className="col-xl-12">
                            <h5 className="mb-4">
                                Ogólne dane
                            </h5>
                            <hr></hr>
                        </div>
                        <div className="col-xl-3" style={{ paddingBottom: "25px" }}>
                            <Card style={{ height: "100%" }}>
                                <CardBody>
                                    <CardTitle className="mb-4">
                                        <i className="bx bx-data font-size-24 align-middle mr-2 text-primary"></i>
                                        Statystyki ogólne łącznie
                                    </CardTitle>
                                    <hr></hr>
                                    <div>
                                        <div className="mt-2">
                                            <span>Liczba projektów: <strong>{ this.state.allFilteredCount }</strong></span>
                                        </div>
                                        <div className="mt-2">
                                            <span>Liczba projektów zapisanych: <strong>{ this.state.filteredProjects.length }</strong></span>
                                        </div>
                                        <div className="mt-2">
                                            <span>Liczba wysłanych wycen: <strong>{ this.state.fileterdValuationsCount }</strong></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        { this.renderObject("Typ - projekty zapisane", this.state.projectsStats.parsedType) }
                        { this.renderObject("Platforma - projekty zapisane", this.state.projectsStats.platform) }
                        { this.renderObject("Wersja - projekty zapisane", this.state.projectsStats.appVersion) }
                        { this.renderObject("Regiony - projekty zapisane", this.state.projectsStats.regions) }

                        <div className="col-xl-12">
                            <h5 className="mb-4">
                                Szafy
                            </h5>
                            <hr></hr>
                        </div>
                        { this.renderObject("Szafy - styl", this.state.projectsStats.wardrobesStats.WardrobeStyle) }
                        { this.renderObject("Szafy - rozmiar", this.state.projectsStats.wardrobesStats.WardrobeSize) }
                        { this.renderObject("Szafy - przeznaczenie", this.state.projectsStats.wardrobesStats.WardrobePurposes) }
                        {/* { this.renderObject("Rodzaje drzwi", this.state.projectsStats.wardrobesStats.DoorType) } */}
                        {/* { this.renderObject("Układ", this.state.projectsStats.wardrobesStats.WardrobeLayout) } */}
                        { this.renderObject("Układ szafy zabudowanej", this.state.projectsStats.wardrobesStats.WardrobeLayout) }
                        {/* { this.renderObject("Kształt", this.state.projectsStats.wardrobesStats.WardrobeShape) } */}
                        {/* { this.renderObject("System", this.state.projectsStats.wardrobesStats.WardrobeSystem) } */}
                        {/* { this.renderObject("Konstrukcja", this.state.projectsStats.wardrobesStats.WardrobeConstruction) } */}
                        {/* { this.renderObject("Moduły", this.state.projectsStats.wardrobesStats.Modules) } */}

                        { this.renderMaterials("Top 10 materiały wnętrza", this.state.projectsStats.wardrobesStats.InteriorMaterial, 'name_app', 10) }
                        { this.renderMaterials("Top 10 materiały pleców", this.state.projectsStats.wardrobesStats.BackMaterial, 'name_app', 10) }
                        { this.renderMaterials("Top 10 materiały korpus", this.state.projectsStats.wardrobesStats.BaseMaterial, 'name_app', 10) }
                        { this.renderMaterials("Top 10 materiał drzwi", this.state.projectsStats.wardrobesStats.DoorMaterials, 'name_app', 10) }

                        { this.renderObject("Materiał wnętrza - typ", this.state.projectsStats.wardrobesStats.InteriorMaterialKind) }
                        { this.renderObject("Materiał pleców - typ", this.state.projectsStats.wardrobesStats.BackMaterialKind) }
                        { this.renderObject("Materiał korpus - typ", this.state.projectsStats.wardrobesStats.BaseMaterialKind) }
                        { this.renderObject("Materiał drzwi - typ", this.state.projectsStats.wardrobesStats.DoorMaterialKind) }
                        
                        { this.renderMaterials("Materiał profilu", this.state.projectsStats.wardrobesStats.ProfileMaterials, 'profile_name', 0) }

                    </div>
                    : null }
                </div>

                <Modal size="sm" isOpen={this.state.loadingProjects} scrollable={true} centered >
                    <div className="modal-body mt-3 text-center">
                                <p><span className="spinner-border spinner-border-sm mr-4 " role="status" aria-hidden="true"></span>Wczytywanie projektów: { Math.round(this.state.loadingProjectsProgress * 100) + '%' }</p>
                                <Progress className="" striped animated color="success" value={this.state.loadingProjectsProgress * 100} ></Progress>
                    </div>
                </Modal>
            </React.Fragment>
        );   
            } else {
                return (
                    <Modal size="sm" isOpen={this.state.isLoading} scrollable={true} centered >
                        <div className="modal-body mt-3 text-center">
                                    <p><span className="spinner-border spinner-border-sm mr-4 " role="status" aria-hidden="true"></span>Wczytywanie...</p>
                        </div>
                    </Modal>
                );
            }
    }
}

export default withRouter(Tools);
