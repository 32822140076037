import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { timestampToMicrotime, monthName } from "../../helpers/commons.js"

class StackedColumnChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: null
        };
    }

    componentDidMount() {
        this.updateChart();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.to !== this.props.to || prevProps.from !== this.props.from) {
          this.updateChart();
        }
    }

    updateChart() {
        //console.log('update');
        let days = Math.ceil((this.props.to.getTime() - this.props.from.getTime()) / (1000 * 3600 * 24));
        console.log(days);
        let offset = 1;
        if (days > 31) {
            offset = 7;
        }
        if (days > 120) {
            offset = 14;
        }

        if (days > 360) {
            offset = 30;
        }

        let totalWeeklyData = [];
        let savedWeeklyData = [];
        let dates = [];
        let dayMicrotime = 1000 * 60 * 60 * 24;
        for (let i = days; i > 0; i -= offset)
        {
            totalWeeklyData.push(this.props.data.filter( e => this.props.to.getTime() - timestampToMicrotime(e.time_created) <  dayMicrotime * i && this.props.to  - timestampToMicrotime(e.time_created) >  dayMicrotime * (i -1 * offset)).length);
            savedWeeklyData.push(this.props.data.filter( e => this.props.to.getTime() - timestampToMicrotime(e.time_created) <  dayMicrotime * i && this.props.to  - timestampToMicrotime(e.time_created) >  dayMicrotime * (i -1 * offset) && e.has_content === "1" ).length);
            let date = new Date();
            date.setTime(this.props.to.getTime() -(i - 1) * dayMicrotime);
            dates.push(date.getDate() + ' ' + monthName(date.getMonth()));
        }

        this.setState({
            series: [{
                name: "Projekty łącznie",
                data: totalWeeklyData
            }, {
                name: "Projekty zapisane",
                data: savedWeeklyData
            }],
            options: {
                chart: {
                    height: 259,
                    type: "line",
                    stacked: !1,
                    toolbar: {
                        show: 0
                    },
                    zoom: {
                        enabled: !1
                    }
                },
                plotOptions: {
                    line: {
                        horizontal: !1,
                        width: "1",
                        // endingShape: "rounded"
                    }
                },
                stroke: {
                    width: [2, 2, 2]
                  },
                dataLabels: {
                    enabled: !1
                },
                xaxis: {
                    categories: dates,
                },
                markers: {
                    size: 5,
                    strokeWidth: 3,
                    fillOpacity: 0,
                    strokeOpacity: 0,
                    hover: {
                      size: 8
                    }
                  },
                colors: ["#556ee6", "#f1b44c", "#34c38f"],
                legend: {
                    position: "bottom"
                },
                fill: {
                    opacity: 1
                }
            },
        })
    }

    render() {
        //console.log(this.state);
        if (this.state.options === null) return null;
        return (
            <React.Fragment>
                <ReactApexChart options={this.state.options} series={this.state.series} type="line" height="200" />
            </React.Fragment>
        );
    }
}

export default StackedColumnChart;
